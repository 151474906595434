<template>
  <section class="text-gray-600 body-font">
    <div class="container px-4 md:px-4 py-4 mx-auto flex flex-wrap">
      <h2 class="font-semibold text-gray-900 mb-6 text-xl md:text-2xl">{{ t('auction.progress') }}</h2>

      <div class="flex flex-wrap w-full justify-between">
        <!-- progress -->
        <div class="md:w-1/2 md:pl-20 md:py-6">
          <div class="flex relative pb-12" v-for="(items,index) in statusMap" :key="index">
            <div class="h-full w-8 absolute inset-0 flex items-center justify-center">
              <div v-if="items.status !== 'closed'" class="h-full w-1 pointer-events-none" :class="{'bg-indigo-500':items.id < statusNum,'bg-gray-200': !(items.id < statusNum) }"></div>
            </div>

            <div class="flex-shrink-0 w-8 h-8 rounded-full inline-flex items-center justify-center text-white relative z-5" :class="{'bg-indigo-500':handleStatus(items.id) === 'done' || handleStatus(items.id) === 'progress','bg-gray-400':  handleStatus(items.id) === 'inactive'}">
              <svg-icon v-if="handleStatus(items.id) === 'done'" icon-class="progress_done" size="small" />
              <div v-else>{{ index + 1 }}</div>
            </div>
            <div class="flex-grow pl-4">
              <h3 class="font-medium title-font text-gray-800 my-1 text-sm">{{ items.label }}</h3>
            </div>
          </div>
        </div>

        <!-- status-->
        <div class="w-1/2 md:py-6">
          <div v-if="status === 'INACTIVE'" class="md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12">
            <p class="text-center text-xl font-semibold text-emerald-500">{{ t('auction.lock') }}</p>
            <button class="btn btn-info text-white hover:bg-blue-400 w-full mt-6" :class="{ loading: state.isLoading }" @click="unlockDomain">
              {{ t('auction.unlock') }}
            </button>
          </div>

          <div v-if="status === 'OPENING'" class="md:w-1/2 object-cover object-center rounded-lg">
            <p class="text-center text-xl font-semibold text-emerald-500">{{ t('auction.opening') }}</p>
            <p class="text-center text-sm mt-4 text-gray-400">({{ t('auction.countDown') }} {{ endTime === 0 || endTime < 0 ? ' --' : handleEndTime(endTime * blockTime) }})</p>
          </div>

          <div class="md:w-2/3 object-cover object-center rounded-lg md:mt-0 mt-12" v-if="status === 'BIDDING'">
            <!-- if current status is settled , users cannot bid  -->
            <div class="flex items-center justify-center text-xl font-semibold text-emerald-500">
              {{ t('auction.bidding') }}
              <span v-if="!isSettle" class="text-sm text-gray-400">({{ t('auction.settle') }}) </span>
              <tool-tip v-if="!isSettle" :tooltip="tooltip" :direction="directionRef" class="ml-4"/>
            </div>
            <p class="text-center mb-10 text-sm mt-4 text-gray-400"> ({{ t('auction.estimateEnd') }} {{ endTime === 0 || endTime < 0 ? ' --' : handleEndTime(endTime * blockTime) }}) </p>

            <div v-if="isSettle && state.showBidInput">
              <div class="relative flex-grow mb-4">
                <input type="text" oninput="value=value.replace(/[^\d.]/g, '')" v-model="state.bidPrice" :placeholder="t('auction.yourPrice')" class="text-sm pl-1 sm:pl-4 w-full focus:border-blue-400 rounded-md border border-gray-300 h-12" />
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center sm:pr-3 pr-1">
                  <span class="text-gray-500 text-sm" id="price-currency">HNS</span>
                </div>
              </div>

              <div class="relative flex-grow mb-4">
                <input type="text" oninput="value=value.replace(/[^\d.]/g, '')" v-model="state.blind" :placeholder="t('auction.blindPrice')" class="text-sm pl-1 sm:pl-4 w-full focus:border-blue-400 rounded-md border border-gray-300 h-12" />
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center sm:pr-3 pr-1">
                  <span class="text-gray-500 text-sm" id="price-currency">HNS</span>
                </div>
              </div>
             
              <button class="btn btn-info w-full mt-4 text-white hover:bg-blue-400" :class="{ loading: state.isLoading }" @click="makeBid">
                {{ t('auction.bidNow') }}
              </button>
            </div>
            <div v-if="!state.showBidInput">
              <div class="flex items-center justify-center">
                <div class="mr-1">{{ t('auction.bidSucceed') }}</div>
                <a class="text-sky-500" @click="state.showBidInput = !state.showBidInput">({{ t('auction.bidAgain') }})</a>
              </div>
            </div>

          </div>

          <div v-if="status === 'REVEAL'" class="md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12">
            <p class="text-center text-xl font-semibold text-emerald-500 flex justify-center items-baseline">{{ t('auction.inReveal') }}
              <span class="text-sm text-gray-400 flex ml-1">
                ({{ t('auction.settle') }})
                <tool-tip :tooltip="tooltip" direction="left" class="ml-1 text-gray-400"/>
              </span>
            </p>
            <p class="text-center mb-10 text-sm mt-4 text-gray-400">({{ t('auction.estimateEnd') }} {{ endTime === 0 || endTime < 0 ? ' --' : handleEndTime(endTime * blockTime) }})</p>

            <button class="btn btn-info text-white w-full hover:bg-blue-400" :class="{ loading: state.isLoading }" @click="revealNow" v-if="props.detailData.history.some((e: any) => e.own) && props.detailData.bids > 0 && props.detailData.status === 'REVEAL' && ((props.detailData.open + revealBlock - props.detailData.height > 1)) && state.isReveal">
              {{ t('auction.revealing') }}
            </button>
          </div>

          <div v-if="status === 'CLOSED'" class="md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12">
            <p class="text-center text-xl font-semibold text-lg text-red-500">{{ t('auction.closed') }}</p>
            <p class="text-center mb-10 text-sm mt-4 text-gray-400">( {{ state.finishTime }} {{ t('auction.hasClosed') }})</p>
            <p class="text-center font-semibold  mb-10">
              <span class="text-gray-500/60">{{ t('auction.closePrice') }}</span> :
              <span class="font-bold text-2xl"> {{ addCommas(detailData.value) }} </span>
              <span class="text-gray-500/60 ml-1">HNS</span>
            </p>

            <!--  if user bid multiple times or didn't win -->
            <button v-if="!props.detailData?.expired && props.detailData?.status === 'CLOSED' && state.isOwnerWin" class="btn btn-info text-white hover:bg-blue-400 w-full" :class="{ loading: state.isLoading }" @click="redeemNow">{{ t('auction.redeem') }}</button>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
  import { reactive, onMounted, watch, ref } from "vue";
  import { createSessions } from '@/utils/customRequest'
  import { useI18n } from "vue-i18n"
  import { timeFormat, handleEndTime, addCommas } from '@/common'
  import message from "@/components/message";
  import ToolTip from '@/components/ToolTip.vue'
  import Input from "@/components/Input.vue";

  const { t } = useI18n()
  const props = defineProps(['history','statusNum','status','detailData','endTime','isSettle'])
  const emit = defineEmits(['reload'])
  
  const tooltip = t('auction.last') + ' ' + Number(import.meta.env.VITE_MODE_BLOCKTIME) + ' ' + t('auction.notOperational')
  let blockTime = Number(import.meta.env.VITE_MODE_BLOCKTIME)
  let revealBlock = Number(import.meta.env.VITE_MODE_REVEAL)
  const directionRef = ref('bottom')
  const state = reactive<any>({
    bidPrice:null,
    blind:null,
    showPay:true,
    isReveal:false,
    isLoading:false,
    finishTime:null,
    isOwnerWin:false,
    showBidInput:true,
    isHidden:true,
    currentStatus:'done'
  })
  const windowWidth = ref(0)
  const statusMap = [
    { id:1, label:'Unreleased', status:'inactive', num:1 },
    { id:2, label:'Released', status:'opening', num:2 },
    { id:3, label:'Auction live', status:'bidding', num:4 },
    { id:4, label:'In reveal', status:'reveal', num:8 },
    { id:5, label:'Auction over', status:'closed', num:16 },
  ]

  const handleStatus = (id: number) => {
    // deliver status number from detail page
    let a = Number(props.statusNum)
    // special case
    if(id === 5 && a === 5) return 'done'
    if(id < a ) return 'done'
    if(id === a) return  'progress'
    if(id > a) return  'inactive'
  }

  const getWindowResize = function () {
    windowWidth.value = window.innerWidth

    if(windowWidth.value < 920) {
      directionRef.value = 'left'
    } else {
      directionRef.value = 'bottom'
    }
  }

  const emitFunction = () => {
    emit('reload', props.detailData.name)
    state.isLoading = false
  }

  const emitBid = () => {
    emit('reload', props.detailData.name)
    state.bidPrice = null
    state.blind = null
    state.isLoading = false
    // state.showBidInput = false
  }

  watch((props),(n) => {
    // console.log(n)
    initHistory(n)
    let nowHeight = props.detailData?.height
    let height = props.detailData?.open + Number(import.meta.env.VITE_MODE_REVEAL)
    state.finishTime = timeFormat(height, nowHeight)
    state.isOwnerWin = props.detailData?.history.some((e: any) => e.reveal && !e.redeem && !e.win && e.own )
    handleStatus(props.statusNum)
  })

  const initHistory = (x: any) => {
    for(let i = 0; i < x?.history.length; i++) {
      if(x?.history[i].own && !x?.history[i].reveal){
        state.isReveal = true
        return
      }
    }
    state.isReveal = false
  }

  // unlock domain
  const unlockDomain = () => {
    state.isLoading = true
    const params = {
      name: props.detailData.name
    }
    createSessions('hns_auction_open', params, emitFunction, 'open')
  }

  // make your bid
  const makeBid = () => {
    if(Number(state.bidPrice) < 0.01) {
      message({ type:'error',str:t('common.least') })
      return
    }
    state.isLoading = true
    const params = {
      name: props.detailData.name,
      value: state.bidPrice,
      lockup: (Number(state.bidPrice) + Number(state.blind)).toFixed(4)
    }
    createSessions('hns_auction_bid', params, emitBid, 'bid')
  }

  // reveal your domain
  const revealNow = () => {
    state.isLoading = true
    const params = {
      name: props.detailData.name
    }
    createSessions('hns_auction_reveal', params, emitFunction, 'reveal')
  }

  // redeem your bid
  const redeemNow = () => {
    state.isLoading = true
    const params = {
      name: props.detailData.name
    }
    createSessions('hns_auction_redeem', params, emitFunction, 'redeem')
  }

  onMounted(() => {
    getWindowResize()
    window.addEventListener('resize', getWindowResize)
    setTimeout(() => {
      initHistory(props.detailData)
    }, 50);
  })
</script>
