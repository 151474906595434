<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
  },
})
</script>

<template>
  <router-view />
</template>
