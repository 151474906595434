const meta = {
  install (app: any, options: any) {
    app.config.globalProperties.$setMeta = function (description: any) {
      const metaDesc = document.createElement('meta')
      metaDesc.name = 'description'
      metaDesc.content = description
      document.getElementsByTagName('head')[0].appendChild(metaDesc)
    }
  }
}

export default meta