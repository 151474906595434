<template>
  <div class="container px-5 mx-auto my-12">
    <h2 class="font-bold mb-6 text-xl md:text-2xl">{{ t('history.sale') }}</h2>
    <table class="table w-full">
      <thead>
        <tr>
          <th class="">{{ t('history.no') }}</th>
          <th class="text-right">{{ t('history.time') }}</th>
          <th class="text-right">{{ t('history.final') }}</th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(items,index) in data" :key="index">
        <td class="p-3 border-gray-500/10 text-gray-500/60 font-medium !text-left">{{ index + 1 }}</td>
        <td class="p-3 border-gray-500/10 text-gray-500/60 font-medium">{{ getTime(items.time) }} </td>
        <td class="font-medium">
          {{ addCommas(items.value) }}
          <span class="text-gray-500/60 ml-1">HNS</span>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="data?.length < 1" class="text-center mt-5 text-sm text-neutral-400">{{ t('common.noData') }}</div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import {addCommas, getTime} from "@/common";

const { t } = useI18n()
const props = defineProps(['data'])

</script>

<style scoped>
th {
  background-color: #F6F6F6;
  border-bottom: 1px solid #eee;
}

td {
  text-align: right;
}
</style>
