import {
  createRouter,
  createWebHistory,
  RouteRecordRaw
} from 'vue-router'
const Layout = () => import('../views/layout/Layout.vue')
import home from '@/views/home/Index.vue'
import DomainDetail from '@/views/name/DomainDetail.vue'
import i18n from '../locale/index'

const { t } = i18n.global

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    children: [
      {
        path: '/',
        component: home,
        name: 'Home',
        meta: {
          title: t('title.home'),
        },
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/views/search/BulkSearch.vue'),
        meta: {
          title: t('title.search'),
        },
      },
      {
        path: '/generator',
        name: 'generator',
        component: () => import('@/views/search/DomainGenerator.vue'),
        meta: {
          title: t('title.generator'),
        },
      },
      {
        path: '/result',
        name: 'result',
        component: () => import('@/views/search/DomainResult.vue'),
        meta: {
          title: t('title.result'),
        },
      },
      {
        path: '/categories',
        name: 'category',
        component: () => import('@/views/category/Category.vue'),
        meta: {
          title: t('title.category'),
        },
      },
      {
        path: '/categories/:id',
        name: 'CategoryDetail',
        component: () => import('@/views/category/CategoryDetail.vue'),
        meta: {
          title: t('title.category'),
        },
      },
      {
        path: '/auction',
        name: 'auction',
        component: () => import('@/views/auction/Index.vue'),
        meta: {
          title: t('title.auction'),
        },
      },
      {
        path: 'names/:name',
        component: DomainDetail,
        name: 'DomainDetail',
        meta: {
          title: t('title.detail'),
        },
      },
      {
        path: '/market',
        name: 'market',
        component: () => import('@/views/market/Index.vue'),
        meta: {
          title: t('title.market'),
        },
      },
      {
        path: 'market/:name',
        name: 'marketDetail',
        component: DomainDetail,
      },
      {
        path: '/my-transaction',
        name: 'my-transaction',
        component: () => import('@/views/transaction/Index.vue'),
        meta: {
          title: t('router.transaction'),
        },
      },
      {
        path: '/docs',
        name: 'docs',
        component: () => import('@/views/docs/Index.vue'),
        meta: {
          title: t('router.docs'),
        },
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        redirect:'/dashboard/collection',
        component: () => import('@/views/dashboard/Index.vue'),
        children:[
          {
            path: '/favourite',
            name: 'favourite',
            component: () => import('@/views/dashboard/Favourite.vue'),
            meta: {
              title: 'Favourite',
            },
          },
          {
            path: '/setting',
            name: 'setting',
            component: () => import('@/views/dashboard/Setting.vue'),
            meta: {
              title: t('title.setting'),
            },
          },
          {
            path: '/myDomain',
            name: 'name',
            component: () => import('@/views/dashboard/Names.vue'),
            meta: {
              title: t('title.name'),
            },
          },
          {
            path: '/marketCenter',
            name: 'marketCenter',
            component: () => import('@/views/dashboard/Market.vue'),
            meta: {
              title: t('title.marketCenter'),
            },
          },
        ]
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
  next()
});
router.afterEach((to, from) => {
  document.title = (to.meta.title as string) + ' - DeName' || 'DeName'//make title by router meta
})
export default router
