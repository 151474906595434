<template>
  <!-- extra div to prevent warning in chrome -->
  <div>
    <div class="container my-12 table-container">
      <h2 class="font-bold mb-6 text-xl md:text-2xl">{{ t('record.record') }}</h2>
      <div class="overflow-x-auto">
        <table class="table w-full">
          <thead>
            <tr>
              <th class="text-left">No.</th>
              <th class="!text-right">{{ t('record.bidAndBlind') }}</th>
              <th class="!text-right">{{ t('record.price') }}</th>
              <th class="text-right">{{ t('record.status') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(items,index) in detailData" :key="index">
              <td class="p-3 border-gray-500/10 text-gray-500/60 font-medium !text-left">{{ index + 1 }}<span class="ml-1" v-if="items.own">(Me)</span></td>
              <td class="p-3 !text-right border-gray-500/10 font-medium w-1/4">
                <span v-if="items.reveal" class="group text-right">{{ (items.value).toFixed(2) + ' + ' + (items.lockup - items.value).toFixed(2)}}<span class="text-gray-500/60"> HNS</span></span>
                <span v-else>{{ t('record.unRevealed')}}</span>
              </td>
              <td class="p-3 !text-right border-gray-500/10 font-medium">
                <span class="group text-right">{{ (items.lockup).toFixed(2) }}</span>
                <span class="text-gray-500/60 ml-1">HNS</span>
              </td>
              <td class="p-3 border-gray-500/10">
                <div class="flex justify-end">
                  <svg-icon size="small" v-if="items.confirmed" icon-class="complete" />
                  <svg-icon v-else icon-class="waiting" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="detailData?.length < 1" class="text-center mt-5 text-sm text-neutral-400">{{ t('common.noData') }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from "vue-i18n";

  const props = defineProps(['detailData'])
  const { t } = useI18n()

</script>

<style scoped>
th {
  background-color: #F6F6F6;
  border-bottom: 1px solid #eee;
}

td {
  text-align: right;
}
@media screen and (max-width: 443px) {
  .table-container {
    padding: 0 1rem;
    margin: 0 auto;
  }
}
</style>
