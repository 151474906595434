import axios from '../utils/axios'

export function getCategoryList(params: any) {
    return axios({
        url: '/hns/keys',
        method: 'get',
        params
    })
}

export function getCategoryDetail(key: any) {
    return axios({
        url: '/hns/key/' + key,
        method: 'get',

    })
}

export function getDomainlistByCategory(params: any) {
    return axios({
        url: '/hns/names' ,
        method: 'get',
        params
    })
}

export function getRankList(params: any) {
    return axios({
        url: '/hns/names',
        method: 'get',
        params
    })
}

export function getFavoriteNames(result: any) {
    return axios({
        url: '/hns/wallet/names/' + result,
        method: 'get',
    })
}

export function deleteMarketPrice(data: any) {
    return axios({
        url: '/hns/market/order',
        method: 'delete',
        data
    })
}