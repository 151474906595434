<template>
  <div class="container px-5 mx-auto my-12">
    <h1 class="font-bold text-xl mb-6">{{ t('info.auctionInfo') }}</h1>
    <div class="info-parts">
      <div class="text-sky-600">{{ t('info.auctionRelease') }}</div>
      <p class="text-sm text-gray-500">{{ timeFormat(state.height + openBlock, state.nowHeight) }} {{ t('info.currentHeight') }} {{ props.detailData?.open }}</p>
    </div>
    <div class="info-parts">
      <div class="text-sky-600">{{ t('info.auctionOpen') }}</div>
      <p class="text-sm text-gray-500">{{ timeFormat(state.height + openBlock, state.nowHeight) }} {{ t('info.currentHeight') }} {{ props.detailData?.open + openBlock }}</p>
    </div>
    <div class="info-parts">
      <div class="text-sky-600">{{ t('info.bidDeadline') }}</div>
      <p class="text-sm text-gray-500">{{ timeFormat(state.height + bidBlock, state.nowHeight) }} {{ t('info.currentHeight') }} {{ props.detailData?.open + bidBlock }}</p>
    </div>
    <div class="info-parts">
      <div class="text-sky-600">{{ t('info.auctionEnd') }}</div>
      <p class="text-sm text-gray-500">{{ timeFormat(state.height + revealBlock, state.nowHeight) }} {{ t('info.currentHeight') }} {{ props.detailData?.open + revealBlock }}</p>
    </div>
    <div class="info-parts">
      <div class="text-sky-600">{{ t('info.currentHeight') }}</div>
      <p class="text-sm text-gray-500">{{ props.detailData?.height }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { timeFormat } from '@/common'
  import { reactive, watch } from "vue";
  import { useI18n } from "vue-i18n";

  const { t } = useI18n()
  const props = defineProps(['detailData'])
  const state = reactive({
    nowHeight:null,
    height:0
  })
  let revealBlock = Number(import.meta.env.VITE_MODE_REVEAL)
  let openBlock = Number(import.meta.env.VITE_MODE_OPENING)
  let bidBlock = Number(import.meta.env.VITE_MODE_BIDDING)
  watch((props),(n) => {
    state.nowHeight = props.detailData?.height
    state.height = props.detailData?.open
  })


</script>
<style scoped>
  .info-parts {
    margin-top:1rem;
  }
</style>
