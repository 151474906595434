<template>
  <div>
    <div class="container px-5 mx-auto my-12">
      <h2 class="font-bold mb-6 text-2xl">{{ t('record.auctionHistory') }}</h2>
      <div class="overflow-x-auto">
        <table class="table w-full">
          <thead>
            <tr>
              <th>NO.</th>
              <th class="!text-right">{{ t('record.bidAndBlind') }}</th>
              <th class="!text-right">{{ t('record.price') }}</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(items,index) in data" :key="index">
            <td class="p-3 border-gray-500/10 text-gray-500/60 font-medium !text-left">{{ index + 1 }}<span class="ml-1" v-if="items.own">(Me)</span></td>
            <td class="p-3 !text-right border-gray-500/10 font-medium">
              <p class="group text-right" v-if="items.reveal">
                {{(items.value).toFixed(2) + ' + ' + (items.lockup - items.value).toFixed(2) }}
                <span class="text-gray-500/60 ml-1">HNS</span>
              </p>
              <span class="group text-right" v-else>{{ t('record.unRevealed') }}</span>
            </td>
            <td class="p-3 !text-right border-gray-500/10 font-medium">
              {{ (items.lockup).toFixed(2) }}
              <span class="text-gray-500/60 ml-1">HNS</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="data?.length < 1" class="text-center mt-5 text-sm text-neutral-400">{{ t('common.noData') }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n()
const props = defineProps(['data'])

</script>

<style scoped>
th {
  background-color: #F6F6F6;
  border-bottom: 1px solid #eee;
}

td {
  text-align: center;
}
</style>
