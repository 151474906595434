import axios from '../utils/axios'

export function getNameList(params: any) {
    return axios({
        url: '/hns/wallet/names',
        method: 'get',
        params
    })
}

// get user market info
export function getOwnMarket(params: any) {
    return axios({
        url: '/hns/market/own',
        method: 'get',
        params
    })
}

// get market list
export function getMarketList(params: any) {
    return axios({
        url: '/hns/market',
        method: 'get',
        params
    })
}

// get trade list
export function getTradeList(params: any) {
    return axios({
        url: '/hns/wallet/names',
        method: 'get',
        params
    })
}

export function getBindStatus(params: any) {
    return axios({
        url: '/email',
        method: 'get',
        params
    })
}

export function getCode(data: any) {
    return axios({
        url: '/email/code',
        method: 'post',
        data
    })
}

export function bindEmail(data: any) {
    return axios({
        url: '/email?module=HNS',
        method: 'post',
        data
    })
}

export function getSubscribeDomain(params: any) {
    return axios({
        url: '/email/sub',
        method: 'get',
        params
    })
}

export function getPersonalItemsCount (type: any) {
    return axios({
        url: '/hns/wallet/names/summary/' + type,
        method: 'get',
    })
}

export function getBalance () {
    return axios({
        url: '/wallet/hns/balance',
        method: 'get'
    })
}