import router from '@/router'

router.beforeEach((to, from, next) => {
  // if user is logout, they shouldn't go to dashboard page
  const visitor = function() {
    if(to.path.match(/^[\(\/]setting.*/) || to.path.match(/^[\(\/]myDomain.*/) || to.path.match(/^[\(\/]marketCenter.*/)) {
      next('/')
    } else {
      next()
    }
  }
  // 用户
  if (localStorage.getItem('key')) { // if connected
    next()
  } else {
    visitor()
  }
})