import { encode, decode } from "punycode";
import message from '@/components/message/index'
import router from "@/router";
import i18n from '../locale/index'
const { t } = i18n.global

export function langs() {
  return [
    { value: "en_US", label: "English" },
    { value: "zh_CN", label: "简体中文" },
  ]
}

export default {
  setLanguage() {
    const userLang = navigator.language
    if (userLang === 'zh-CN' || userLang === 'zh-cn' || userLang === 'zh') {
      localStorage.setItem('language', 'zh_CN')
        return 'zh_CN'
    } else {
      localStorage.setItem('language', 'en_US')
      return 'en_US'
    }
  },
}

export function cutString(str:any, len1:any, len2: any) {
    let strlen = str.length
    if ( strlen < len1+len2) return str
    let symbol = '...'
    str = str.substr(0,len1) + symbol + str.substr(strlen-len2)

    return str
}

export function shortenString(str:any, len:any) {
    let strlen = str.length
    if ( strlen < len) return str
    let symbol = '...'
    str = str.substr(0,len) + symbol

    return str
}

export function getTime(time:any) {
    let date = new Date(time * 1000);  // * 1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = date.getDate() < 10 ? '0' + date.getDate() + ' ': date.getDate() + ' ';
    let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':' ;
    let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return Y+M+D+h+m
}

export function handleStatus (status: any) {
    switch (status) {
        default:
            return 0;
        case 'INACTIVE':
            return 0
        case 'OPENING':
            return Number(import.meta.env.VITE_MODE_OPENING)
        case 'BIDDING':
            return Number(import.meta.env.VITE_MODE_BIDDING)
        case 'REVEAL':
            return Number(import.meta.env.VITE_MODE_REVEAL)
        case 'CLOSED':
            return 0
    }
}

let blockTime = Number(import.meta.env.VITE_MODE_BLOCKTIME)
export function handleLeftTime (open:any, height: any, status: any) {
    let newTime = (open + handleStatus(status) - height) * blockTime
    let day = parseInt(Math.floor(newTime / 1440) as any)
    let hour = day > 0 ? Math.floor((newTime - day * 1440) / 60) : Math.floor(newTime / 60);
    let minute = Math.floor(newTime - day * 1440 - hour * 60);
    let time = ''
    if (day > 0) time += day + t('common.day');
    if (hour > 0) day > 0 ? time += ' '+ hour + t('common.hour') : time += hour + t('common.hour')
    if (minute > 0) hour > 0 ? time += ' '+ minute + t('common.minute') : time += minute + t('common.minute')
    if (time == '') time = '00 ' + t('common.minute')
    return time
}

export function handleEndTime (leftTime: any) {
    let day = parseInt(Math.floor(leftTime / 1440) as any)
    let hour = day > 0 ? Math.floor((leftTime - day * 1440) / 60) : Math.floor(leftTime / 60);
    let minute = Math.floor(leftTime - day * 1440 - hour * 60);
    let time = ''
    if (day > 0) time += day + t('common.day');
    if (hour > 0) day > 0 ? time += ' '+ hour + t('common.hour') : time += hour + t('common.hour')
    if (minute > 0) hour > 0 ? time += ' '+ minute + t('common.minute') : time += minute + t('common.minute')
    return time;
}

export function handleName(name: any) {
    let str = encode(name).toLowerCase()
    str = str[str.length - 1] == '-' ? str.substring(0, str.length - 1) : `xn--${str}`
    return str
}

export function punycodeTransfer(name: string) {
    // deal with special case
    // if(name.length > 36 || name.length < 10) return name
    if(name.startsWith('xn--')) return decode(name.replaceAll('xn--',''))
    return name
}

export enum SaleStep {
    LOCK = 0,
    FINALIZE = 1,
    SALE = 2,
    BOUGHT = 3,
    UNSHELVE = 4,
}

export const HNS_OWNER_ACTIVE_TYPE = ['REGISTER', 'UPDATE', 'RENEW', 'FINALIZE']

export function getFormatTime(time: any) {
    let date = new Date(time * 1000) ;
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = date.getDate() < 10 ? '0' + date.getDate() + ' ': date.getDate() + ' ';
    return Y+M+D
}

export function timeFormat (height: any, nowHeight: any) {
    let nowTime = new Date().getTime()
    return getFormatTime(Number(nowTime / 1000) - (nowHeight - height) * Number(blockTime) * 60)
}

export function getSearchNames (searchName: any) {

    let str = encode(searchName).toLowerCase()
    str = str[str.length - 1] == '-' ? str.substring(0, str.length - 1) : `xn--${str}`
    str = str.trim()

    if(!str || str === 'xn--' || str.indexOf('\\') > -1  || str.indexOf('*') > -1 || str.indexOf('/') > -1 || str.indexOf('%') > -1 || str.indexOf('#') > -1 || str.indexOf('.') > -1 || str.indexOf(',') > -1 || str.indexOf('?') > -1){
        return message({ type:'warn', str:t('auction.invalid') })
    }

    router.push({
        path: `/names/${str}`,
    })

}

export function addCommas (num: Number) {
    return num.toLocaleString()
}
