import { defineStore } from 'pinia';
import { getLanguage } from "@/utils/auth";

export const useUserStore = defineStore(
  // unique ID
  'user',
  
  {
    persist: true,
    state: () => ({
      userKey:'',
      address:'',
      balance:0,
      userCollection:[] as any,
      connector:null as any,
      language: getLanguage(),
      isDesc: false,
    }),
    getters: {},
    actions: {},
  },
);