// @ts-nocheck
import store from '@/store'
import { getTransactionDetail, sendTransaction, fillMarketOrder, getAvailableCoins, createOrder, broadcastOrder, generateTx } from "@/api/auction";
import message from "@/components/message";
import WalletConnect from "@walletconnect/client"
import i18n from '../locale/index'

const { t } = i18n.global
let userStore = store.state.value

const generateMsg = (type: string) => {
  switch (type) {
    default:
      return 'Operate successfully'
    case 'OPEN':
      return 'Open successfully'
    case 'BID':
      return 'Bid successfully'
    case 'REVEAL':
      return 'Reveal successfully'
    case 'REDEEM':
      return 'Redeem successfully'
    case 'TRANSFER':
      return 'Transfer is in progress'
    case 'RENEW':
      return  'Renew successfully'
    case 'REGISTER':
      return  'Activate successfully'
    case 'FINALIZE':
      return  'Operate successfully'
    case 'UPDATE':
      return  'Cancel successfully'
  }
}

const handleFail = (callback:any) => {
  callback()
}

const checkConnection = async() => {
  if (!userStore.user.connector.connected) {
    userStore.user.connector = new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      clientMeta:{
        description:'domain auction dapp',
        url:'',
        icons:['https://file.fxwallet.com/logo/dename.svg'],
        name:'DeName',
      }
    });
  }
}

const listenDisconnect = () => {
  userStore.user.connector.on("disconnect", (error: any, payload: any) => {
    if (error) {
      throw error;
    }
    resetConnection();
  });
}

const resetConnection = async () => {
  message({type:'error', str:t('common.disconnect')})
  store.userKey = ''
  store.address = ''
  localStorage.removeItem('key')
  localStorage.removeItem('walletconnect')
  localStorage.removeItem('user')
  location.reload()
};

const makeRequest = (session: any, callback?: any) => {
  checkConnection()
  listenDisconnect()
  userStore.user.connector.sendCustomRequest(session).then((result: any) => {
    let infoMsg = ''
    if(result) {
      if(result === 'ok') {
        infoMsg = t('common.operateSuccessFully')
        message({ type:'success',str:infoMsg })
      } else if(result === 'cancel') {
        infoMsg = t('common.operationCancel')
        message({ type:'warn',str:infoMsg })
      } else if(result === 'notOperated') {
        infoMsg = t('common.notOperated')
        message({ type:'warn',str:infoMsg })
      } else {
        // if transaction is done
        getTransactionDetail(result).then((res) => {
          infoMsg = generateMsg(res.data.vout[0].type)
          message({ type:'success',str:infoMsg })
        })
      }

      if(callback) {
        callback()
      }
    }
  })
}

const appRequest = (session: any, callback?: any) => {
  window.web3?.currentProvider.request(session).then((result: any) => {
    let appMsg = ''
    if(result) {
      if(result === 'ok') {
        appMsg = 'Operate successfully'
        message({ type:'success',str:appMsg })
      } else if(result === 'cancel') {
        appMsg = t('common.operationCancel')
        message({ type:'warn',str:appMsg })
      } else {
        // if transaction is done
        getTransactionDetail(result).then((res) => {
          appMsg = generateMsg(res.data.vout[0].type)
          message({ type:'success',str:appMsg })
        })
      }

      if(callback) {
        callback()
      }
    }
  })
}

const handleAvailable = async (coin:string, obj: any, params: any, data: any, type: any, address:any, amount:value) => {
  const res = await getAvailableCoins(coin, obj);
  let flag = []
  if(res.data.items.length > 0) {
    flag[0] = 'change'
    flag[1] = res.data.items[0]
    return flag
  } else {
    const txParams = [{
      address:address,
      amount: amount / 1000000
    }]
    const txData = await generateTx('hns', {'outputs': txParams})
    let tx
    const session = {
      method:'hns_sendTransaction',
      params: [
        {
          name: params.name,
          type: type,
          data: txData.data,
          value: amount / 1000000
        }
      ]
    }
    if (window.web3?.currentProvider?.isInject) {
      tx = await window.web3?.currentProvider.request(session)
    } else {
      tx = await userStore.user.connector.sendCustomRequest(session)
    }
    flag[0] = txData.data
    if(tx === 'cancel') return 'cancel'
    return flag
  }
}

const handleMarketBid = async(method:string, params?:any, callback?:any, type?:any, transactionData?:any) => {
  params.version = 1
  const res = await createOrder(params)
  let data = res.data
  let outputLength = data.outputs.length
  let item = {
    name: params.name,
    type: type,
    value: params.value,
    profit: data.outputs[outputLength - 1]?.value,
    address: data.outputs[outputLength - 1]?.address
  }
  if(type === 'buy') {
    //  buyer is bidding but has no proper UTXO, send a transaction to get UTXO
    const obj = {
      'mempool': 1,
      'value': params.value,
    }
    const res = await handleAvailable('hns', obj, params, data, 'send', item.address, item.value)
    if(res === 'cancel') {
      message({ type: "warn", str: 'Operation has been canceled' })
      callback()
      return
    }
    if(res.length === 1) {
      // const pendingCoin = res[0].outputs[0];
      const newObj = {
        'mempool': 1,
        'value': params.value,
      }
      // when you send your transaction, send transaction again to get UTXO
      const result  = await getAvailableCoins('hns', newObj);
      data.inputs.push(result.data.items[0])
    } else {
      data.inputs.push(res[1])
    }
  }

  const session = {
    method:'hns_signAnyoneCanPay',
    params: [
      {
        name: params.name,
        type: type,
        value:params.value / 1000000,
        data: data
      }
    ]
  }

  let fullItem
  if (window.web3?.currentProvider?.isInject) {
    fullItem = await window.web3?.currentProvider.request(session)
  } else {
    fullItem = await userStore.user.connector.sendCustomRequest(session)
  }

  if(fullItem) {
    if(fullItem === 'cancel') {
      message({ type:'warn',str:t('common.operationCancel') })
      callback()
      return
    }
    const res = await broadcastOrder(fullItem)
    if(res) {
      message({ type: "success", str: 'Transaction has been broadcasted' })
      callback()
    }
  }
}

export async function createSessions(method:string, params?:any, callback?: any, type?:any, marketType?:any) {
  // if it is a dapp on app
  if (window.web3?.currentProvider?.isInject){
    let transactionData = {} as any
    if(method === 'hns_market_bid' || method === 'hns_market_price') {
      await handleMarketBid(method, params, callback, type, transactionData)
    } else {
      if(marketType){
        fillMarketOrder(params).then((res) => {
          transactionData = res.data
          const session = {
            method:'hns_sendTransaction',
            params: [
              {
                name: params.name,
                type: type,
                data: transactionData,
              }
            ]
          }
          appRequest(session, callback)
          return
        })
        .catch((error) => {
          return
        })
      } else {
        sendTransaction(type, params).then((res) => {
          transactionData = res.data
          const session = {
            method:'hns_sendTransaction',
            params: [
              {
                name: params.name,
                type: type,
                data: transactionData
              }
            ]
          }
          if(type === 'bid') {
            session.params[0].lockup = params.lockup
          }
          appRequest(session, callback)
        })
        .catch((error) => {
          callback()
        })
      } 
      
    }
  } else {
    if(!userStore.user.userKey) {
      message({ type: "error", str: t('common.connectTip') })
      handleFail(callback)
      return
    }
    let transactionData = {} as any
    if(method === 'hns_market_bid' || method === 'hns_market_price') {
      checkConnection()
      await handleMarketBid(method, params, callback, type, transactionData)
    } else {
      // if it is accept now and buy now
      if(marketType){
        fillMarketOrder(params).then((res) => {
          transactionData = res.data
          const session = {
            method:'hns_sendTransaction',
            params: [
              {
                name: params.name,
                type: type,
                data: transactionData,
              }
            ]
          }
          makeRequest(session, callback)
          return
        })
        .catch((error) => {
          return
        })
      } else {
        sendTransaction(type, params).then((res) => {
          transactionData = res.data
          const session = {
            method:'hns_sendTransaction',
            params: [
              {
                name: params.name,
                type: type,
                data: transactionData,
              }
            ]
          }
          if(type === 'bid') {
            session.params[0].lockup = params.lockup
          }
          makeRequest(session, callback)
        })
        .catch((error) => {
          callback()
        })
      }
    }
  }
}
