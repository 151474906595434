import Axios from 'axios'
import message from '@/components/message/index'
import router from "@/router";
// const baseURL = 'https://api.fxfi.io:8765'
const baseURL = import.meta.env.VITE_BASE_URL
// const baseURL = '/api'
import i18n from '../locale/index'

const { t } = i18n.global
const axios = Axios.create({
  baseURL,
  timeout: 20000 // timeout 20s
})

// request interceptors
axios.interceptors.request.use(
  (config: any) => {
    // add important key here

    // const regex = /^(\/hns\/wallet\/names|hns\/name|\/hns\/market\/own|wallet\/hns\/tx|\/hns\/email\/|email|\/hns\/wallet\/auction(?:\/\w+)?)/;
    // const match = config.url.match(regex)

    // if (match) {
    //   console.log(123)
    //   config.headers['x-pubkey'] = localStorage.getItem('key')
    // }
    config.headers['x-pubkey'] = localStorage.getItem('key')
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response interceptors
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.data) {
    const code = error.response.status

    if (code === 401) {
      router.push('/')
      message({ type: "error", str: t('common.connectTip') })
      return
    }
    const msg = error.response.data.message
      message({ type: "error", str: msg })
      console.error(`[Axios Error]`, error.response)
    } else {
      message({ type: "error", str: `${error}` })
    }
    return Promise.reject(error)
  }
)

export default axios