<template>
  <div @mouseenter="state.isHidden = !state.isHidden" @mouseleave="state.isHidden = !state.isHidden" class="ml-0 whitespace-pre-line tooltip text-left" :data-tip="tooltip" :class="{ 'tooltip-open': !state.isHidden, 'tooltip-close':state.isHidden, 'tooltip-left': direction ==='left', 'tooltip-bottom': direction ==='bottom' }">
    <img :src="getImage()" alt="tips" class="w-5 h-5">
  </div>
</template>

<script lang="ts" setup>
import { reactive, computed } from "vue";
import toolImg from '../assets/images/svg/tooltip.svg'
import exchangeImg from '../assets/images/svg/exchange.svg'
const props = defineProps({
  tooltip: {type: String},
  direction: {type: String,default: 'bottom'},
  imgUrl: {
    type: String,
    default: 'tooltip'
  }
})

const state = reactive({
  isHidden:true
})

const getImage = () => {
  switch (props.imgUrl) {
    case 'tooltip':
      return toolImg
    case 'exchange':
      return exchangeImg
    default:
      return toolImg
  }
}
</script>

<style scoped>
.tooltip.tooltip-hidden:after,
.tooltip.tooltip-hidden:before,
.tooltip:hover.tooltip-hidden:after,
.tooltip:hover.tooltip-hidden:before {
  opacity: 1;
  transition-delay: 75ms;
}
</style>
