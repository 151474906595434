<template>
  <div class="container px-5 mx-auto my-12">
    <h1 class="font-bold text-xl mb-6">{{ t('rule.auctionRule') }}</h1>
    <ul>
      <li class="mb-4">{{ t('rule.rule1') }}</li>
      <li class="mb-4">{{ t('rule.rule2') }}</li>
      <li class="mb-4">{{ t('rule.rule3') }}</li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
  import { useI18n } from "vue-i18n"

  const { t } = useI18n()
</script>
<style scoped>
  ul {
    counter-reset: sectioncounter;
  }
  li:before {
    content: counter(sectioncounter) ". ";
    counter-increment: sectioncounter;
  }
</style>
