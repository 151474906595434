<template>
  <div class="bg-gray-100 md:mb-12 md:h-48 flex px-4 py-4">
    <base-input class="w-180 inset-0 m-auto" :suffixIcon="imgUrl" @keyup.enter.native="handleSearch()" v-model="state.searchName" :placeholder="t('home.searchPlaceholder')" />
  </div>

  <div class="grid sm:grid-cols-4 xl:grid-cols-8 gap-6 max-w-screen-xl container md:px-5 mx-auto">
    <!-- category -->
    <div class="col-span-3 xl:col-span-8 group h-136 bg-white rounded-lg overflow-hidden md:shadow p-6 md:px-8 md:py-6">
      <div class="flex justify-between items-center">
        <h2 class="font-semibold text-xl">{{ t('home.category') }}</h2>
        <router-link class="text-indigo-500 cursor-pointer hidden md:flex" :to="{ path: `/categories`}">
          More >
        </router-link>
      </div>
      <div v-if="state.categoryList.length > 0"  class="grid sm:grid-cols-3 gap:3 mt-8">
        <router-link class="flex items-center mb-8 cursor-pointer" v-for="(items,index) in state.categoryList" :key="index" :to="{ path: `/categories/${items.key}`}">
          <div class="flex flex-wrap gap-2 mr-4 justify-center">
            <span class="flex justify-center items-center text-white text-center w-12 h-12 bg-gray-800 border ring-2 ring-offset-1 ring-gray-800 rounded-full transition duration-100">
              {{ items.key }}
            </span>
          </div>
          <div>
            <div class="font-bold">{{ items.info[userStore.language]?.name }}</div>
            <p class="text-gray-400 text-xs font-normal">{{ items.info[userStore.language]?.description }}</p>
          </div>
        </router-link>
      </div>
      <spinner v-else class="" />
    </div>
  </div>
  <!-- top auction and top sales -->
  <div class="max-w-screen-xl grid sm:grid-cols-6 gap-6 mt-2 md:mt-12 container md:px-5 mx-auto">
    <div class="col-span-2 group h-115 bg-white rounded-lg overflow-hidden md:shadow px-6 py-4 min-h-115">
      <top-bid type="week_bid" :title="t('home.weekTop')" />
    </div>
    <div class="col-span-2 group h-115 bg-white rounded-lg overflow-hidden md:shadow px-6 py-4 min-h-125">
      <top-bid type="month_bid" :title="t('home.monthTop')" />
    </div>
    <!-- top 3  -->
    <div class="col-span-2 group h-115 bg-white rounded-lg overflow-hidden md:shadow px-6 py-4 min-h-125">
      <div class="flex justify-between items-center">
        <h2 class="font-semibold text-xl">{{ t('home.topBids') }}</h2>
      </div>
      <div class="grid grid-cols-10 mt-4">
        <div class="col-span-2 group text-gray-400 text-left text-xs">ID</div>
        <div class="col-span-5 group text-gray-400 text-xs">{{ t('home.domainName') }}</div>
        <div class="col-span-3 group text-gray-400 text-right text-xs">{{ t('common.price') }}</div>
      </div>
      <div v-if="state.topList.length > 0"  class="grid grid-cols-10 mt-4" v-for="(items, index) in state.topList">
        <div class="col-span-2 group font-semibold" :style="{color: colorMap[index]}">{{ index + 1 }}</div>
        <router-link class="col-span-4 group font-bold text-indigo-400 cursor-pointer" :to="{ path: `/names/${items.name}` }">
          {{ punycodeTransfer(items.name) }}
        </router-link>
        <div class="col-span-4 group text-right">
          {{ addCommas(items.value) }} <span class="text-gray-500/60">HNS</span>
        </div>
      </div>
      <spinner v-else />
      <div class="grid grid-cols-10 mt-4" v-for="(items, index) in state.rankList">
        <div class="col-span-2 group">{{ index + 4 }}</div>
        <router-link class="col-span-4 group text-indigo-400 cursor-pointer" :to="{ path: `/names/${items.name}` }">
          {{ punycodeTransfer(items.name) }}
        </router-link>
        <div class="col-span-4 group text-right">
          {{ addCommas(items.value) }} <span class="text-gray-500/60">HNS</span>
        </div>
      </div>
    </div>
  </div>

</template>
<script lang="ts" setup>
import { onMounted, reactive, getCurrentInstance } from "vue"
import { useRouter } from "vue-router"
import { getCategoryList, getRankList } from '@/api/hns'
import { useI18n } from 'vue-i18n'
import { useUserStore } from "@/store"
import { getSearchNames, punycodeTransfer, addCommas } from '@/common'

import BaseInput from '@/components/Input.vue'
import TopBid from './component/TopBid.vue'
import imgUrl from '@/assets/images/import.svg'

const { t } = useI18n()
const { proxy } = getCurrentInstance() as any
proxy.$setMeta(t('seo.home'))

const userStore = useUserStore() as any
const router = useRouter()
const state = reactive<any>({
  searchName:'',
  categoryList:[],
  rankList:[],
  topList:[],
  listQuery:{
    limit:15,
    offset:0,
  },
  isOpen:false
})
const colorMap = [
  '#DF3636','#DF9136','#EEE73C'
]

const handleSearch = () => {
  getSearchNames(state.searchName)
}

const loadCategories = () => {
  getCategoryList(state.listQuery).then((res) => {
    state.categoryList = res.data.items
  })
}

const loadRankList = () => {
  const params = {
    rank: 'high_value'
  }
  getRankList(params).then((res) => {
    state.rankList = res.data.items
    state.topList = state.rankList.splice(0,3)
  })
}

onMounted(() => {
  loadCategories()
  loadRankList()
})

</script>
