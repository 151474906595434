import { createI18n } from 'vue-i18n'
import zhCN from './zh_CN'
import enUS from './en_US'

const i18n = createI18n({
  legacy:false,
  locale: localStorage.getItem('language') || 'en_US',
  messages: {
    'zh_CN': zhCN,
    'en_US': enUS
  }
})
export default i18n