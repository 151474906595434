export default {
    common: {
        price:'价格',
        amount:'金额',
        more:'更多',
        noData:'暂无数据',
        minute:'分',
        day:'天',
        hour:'小时',
        domain:'域名',
        least:'出价不能低于0.01HNS',
        notenough:'余额不足',
        cancel:'取消',
        confirm:'确认',
        transfer:'转移',
        purchase:'购买',
        operationCancel:'操作已取消',
        codeSent:'验证码已发送',
        connectTip:'请先连接钱包后，再进行操作',
        disconnect:'连接已断开',
        notConnected:'您的连接已断开，请在app端进行确认',
        notOperated:'您未进行任何操作，请在app端进行确认',
        operateSuccessFully:'操作成功',
        finalize:'确认',
        language:'语言',
        account:'账号',
        return:'返回',
        copy:'复制成功',
    },
    router: {
        home:'首页',
        auction:'拍卖',
        market:'市场',
        transaction:'我的交易',
        docs:'文档',
        myDomain:'我的域名',
        myMarket:'我的市场',
        setting:'使用设置',
        logout:'退出登录',
    },
    home: {
        category:'分类',
        wallet:'连接钱包',
        topBids:'最高出价',
        domainName:'域名',
        topSale:'最高成交额',
        topAuction:'最高拍卖价格',
        plus:'出价（+盲注）',
        bulkSearch:'批量搜索',
        weekTop:'一周内最高出价',
        monthTop:'一月内最高出价',
        searchPlaceholder:'请输入您觉得有价值的顶级域名',
        search:'搜索',
        reserve:'保留域名',
        backTo:'返回',
        connected:'已连接钱包地址:'
    },
    auction: {
        dealPrice:'成交价',
        auction:'拍卖',
        opening:'即将开始',
        bidding:'竞价中',
        reveal:'揭示中',
        sold:'已售出',
        domain:'域名',
        bidTimes:'出价次数',
        leftTime:'剩余时长',
        untilEnd:'距离结束',
        leftBlock:'剩余区块',
        progress:'拍卖进度',
        lock:'未解锁',
        unlock:'解锁',
        countDown:'预计开始倒计时',
        yourPrice:'你的出价',
        blindPrice:'盲注出价（可选）',
        bidNow:'立即出价',
        estimateEnd:'预计结束倒计时',
        settle:'结算期',
        inReveal:'揭示中',
        revealing:'揭示',
        closed:'竞拍结束',
        hasClosed:'拍卖已结束',
        closePrice:'拍中价格',

        redeem:'赎回',
        endTip:'距离时间是预估的最佳时间,\n期间有可能因为区块延迟造成\n推迟,具体请以最终时间为准。',
        reserve:'当前域名为已保留域名',
        tryOther:'您搜索的域名为已保留域名，请搜索其他域名试试吧',
        auctionInfo:'域名信息',
        invalid:'无效域名',
        last:'最后',
        notOperational:'分钟是结算时间。域名在此期间不可使用。',
        bidSucceed:'出价成功',
        bidAgain:'再次出价'
    },
    sell: {
        market:'市场',
        label:'标价',
        time:'时间',
        bid:'出价',
        operation:'操作',
        deal:'成交',
        no:'序号',
        status:'状态',
        saleHint:'出售操作提示',
        hint2:'此操作将扣除0.5%的服务费(由FxWallet收取)以及交易的链上费用。',
        hint3:'你会收到交易完成后扣除的金额。请确保你想接受这个出价。',
        hint4:'',
        hint5:'',
        delete:'删除',
        edit:'修改',
        buyNow:'立即购买',
        changeOffer:'修改出价',
        currentPrice:'当前价格:',
        enterAmount: '请输入金额',
    },
    history:{
        sale:'历史记录',
        no:'序号',
        time:'时间',
        final:'成交价'
    },
    rule: {
        auctionRule:'竞拍规则',
        rule1:'Handshake每周会发布一批新的域名，首次出价后，任何人都可以在一周内参加竞标，每个用户可以多次出价',
        rule2:'竞拍截至后，每个竞拍者的出价都会被显示，实际出价最高者获胜并支付第二高价者的实际竞标额，且获得该域名的所有权',
        rule3:'如果您不是最高的出价者，请您对域名进行赎回，域名可在待赎回中查看。'
    },
    info:{
        auctionInfo:'竞拍信息',
        auctionRelease:'竞拍发布时间',
        auctionOpen:'竞拍开始时间',
        bidDeadline:'出价截至时间',
        auctionEnd:'拍卖结束时间',
        currentHeight:'当前区块高度',
    },
    record: {
        record:'竞拍记录',
        bidAndBlind:'出价 + 盲注',
        price:'金额',
        status:'状态',
        unRevealed:'待揭示',
        auctionHistory:'历史竞拍记录',

    },
    owner: {
        activate:'激活',
        transfer:'转移域名',
        renew:'续费',
        transferring:'您正在进行域名转移操作: 等待中',
        later:'域名正在转移中，请稍后查看结果',
        inputTransfer:'输入转移地址',
        cancelTransfer:'取消转移',
        confirmTransfer:'确认转移',
        currentHeight:'当前区块高度',
        renewEnd:'续费结束区块',
        expireTime:'过期时间',
        renewNow:'马上续费',
        sell:'我想卖',
        sale:'出售',
        lock:'锁定',
        confirm:'确认',
        bid:'出价',
        complete:'完成',
        unlock:'解锁',
        unlockTip:'解锁操作提示',
        continue:'当前您已成功锁定域名，您可以继续进行出价。',
        cancelTip:'解锁即为取消本次出售',
        changeLabelPrice:'修改标价',
        labelPrice:'标价',
        enterPrice:'输入金额',
        price:'金额',
        transferLockHint:'您的域名正在转移/锁定中，请进行确认/取消操作',
        transferUp:'您的域名正在转移/锁定中，请在',
        transferDown:'后进行确认/取消操作',
        expire:'域名即将过期',
        inProgress:'交易进行中，请稍候',
        notActivate:'域名未激活，暂时不能进行转移、续费等操作',
        confirmAndCancel:'您的域名正在转移/锁定中，请进行确认/取消操作',
        deleteAndContinue:'确定要删除您的出价，是否继续?',
        deletingBid: '您正在删除您的出价',
        buyNowTip:'您正在直接购买该域名',
        wantToContinue: '是否继续?',
        invalidAddress:'当前地址不合法，请检查后重新输入',
    },

    generator: {
        generator:'生成器',
        pattern:'规则',
        example:'例子:',
        inputPattern:'输入你的规则, i.e. AABBCC',
        inputDomain:'输入多个域名，用逗号，分号，空格或换行分隔',
        modifier:'修饰（可选）',
        fix:'Fix',
        prefix:'前缀',
        suffix:'后缀',
        repetition:'重复',
        filter:'过滤（可选）',
        startOrEnd:'以开头/结尾',
        startWith:'以开头',
        endWith:'以结尾',
        containOrNot:'是否包含',
        contain:'包含',
        notContain:'不含',
        search:'搜索',

        bulkSearch: '批量搜索',
        nothing:'没有想法？',
        generateOne:'去生成一个',
        clearAll:'清除所有',
        bid:'出价',
        continue:'继续搜索',

    },
    market: {
        onSale:'出售中',
        saleRank:'出售排行',
        shelfTime:'上架时间',
        dealTime:'成交时间',
        domainPrice:'域名价格'
    },
    trade:{
        bidTimes:'出价次数',
        revealAll:'揭示所有',
        redeemAll:'赎回所有',
        bidding:'竞价中',
        waitForReveal:'待揭示',
        revealing:'揭示中',
        redeem:'赎回',
        leftTime:'剩余时间'
    },
    dashboard:{
        my:'我的域名',
        myDomain: '我的域名',
        market: '我的市场',
        deviceXpubKey:'设备xpubkey',
        all:'全部',
        notActivate:'未激活',
        expireSoon:'即将过期',
        myMarket:'我的市场',
        selling:'出售中',
        waitForSale:'待出售',
        notForSale:'不可出售',
        myOffer:'我的出价',
        waitForConfirm:'待确认',
        sold:'已售出',
        emailSetting:'邮箱设置',
        forSafety:'用于接受域名转移、续费、出售等重要通知，保障您的域名安全',
        notSet:'未设置',
        set:'已设置',
        bind:'绑定',
        emailBind:'绑定邮箱',
        bidTimes:'出价次数',
        bidPrice:'竞拍价',
        setting:'设置',
        change:'修改',
        changeSuccess:'修改成功',
        bindSuccess:'绑定成功',
        inputCode:'请输入验证码',
        transferring:'转移中'
    },
    title:{
        home:'首页',
        search:'搜索',
        generator:'生成器',
        result:'结果',
        category:'类别',
        auction:'拍卖',
        detail:'域名详情',
        market:'市场',
        setting:'设置',
        name:'我的域名',
        marketCenter:'我的市场',
    },
    docs:{
        marketHelp: '市场 - 帮助文档',
        decentralization: '去中心化',
        decenterDescription: '市场使用 <a title="Non-Interactive Name Atomic Swaps" href="https://hsd-dev.org/HIPs/proposals/0001/">HIP-0001</a> 和 <a title="Trustless Live Auctions for Handshake" href="https://hsd-dev.org/HIPs/proposals/0006/">HIP-0006</a> 的协议规范，实现去中心化的域名交易，交易上链，保证域名安全性。',
        saleName: '出售域名',
        lockName: '1. 出售准备 - 锁定域名',
        lockNameDescription: '出售前需要将域名转移到属于您的脚本地址，并等待约两天，再发送交易进行确认或取消。确认的域名可以进行下一步，取消则会回到原来的状态。',
        bidName: '2. 出售中 - 出价',
        bidNameDescription: '确认锁定后，您可以对域名进行标价，标价在出售过程中可以随时修改。',
        counterName: '3. 出售中 - 他人还价',
        counterNameDescription: '域名出售过程中，他人可以给出自己期望的价格，出价列表会展示在域名详情内。',
        fillSale: '4. 完成 - 出售完成',
        fillPurchase: '出售期间，买方可以直接按标价购买，交易上链后完成出售。',
        fillCounter: '在等待他人购买的同时，他人也可能对域名进行还价；还价会列在域名详情内，您也可以接受他人的出价，直接成交。',
        fillHint1: '注：接受他人出价后，实际得到的HNS会略小于出价，因为需要从中扣除掉两部分费用：由 FxWallet 收取的 0.5% 的服务费+交易上链的手续费。',
        cancelSale: '5. 完成 - 取消出售',
        cancelSaleDescription: '期间也可以取消出售，先解锁域名，等待约两天后，可以确认解锁。',
        cancelSaleHint1: '注：解锁域名只能确认，无法取消，如果改变想法，需要先确认解锁，再重新进行锁定。',
        unlockTitle: '解锁操作提示',
        unlockOpHint: '当前您已成功锁定域名，您可以继续进行出价。\n\n解锁即为取消本次出售。',
        buyName: '购买域名',
        showOnSale: '1. 可以购买的域名',
        showOnSaleDescription: '市场内会列出所有当前出售中的域名，域名详情内会列出具体的出售情况。',
        toCounterName: '2. 还价',
        toCounterNameDescription: '如果对标价不满意，您也可以进行还价，输入自己期望的价格，等待卖方确认；出价可以随时修改。',
        toCounterNameHint1: '注：由于交易完全是去中心化的，每次出价会需要您有一个价格刚好的交易块，所以如果当前没有满足条件的块，会先向自己发送一笔交易整合出合适的块，这个过程会上链，且消耗手续费。',
        toCounterNameHint2: '注：出价并不会上链，所以实际上这个交易块还可以被花费。我们尽量保证您先花费未出价的交易块，如果不得不使用，将会在您支付之前进行提示。如果您看到提示后还是选择花费，之前的出价将无效。',
        buyNowName: '3. 直接购买',
        buyNowNameDescription: '您也可以选择直接购买，您将付出卖方的标价并加上交易的手续费的HNS，成功购买到域名。',
        confirmName: '4. 购买确认',
        confirmNameDescription: '购买成功后，需要等待约两天，然后进行购买确认，确认后，域名将正式属于您。',
    },
    seo: {
        home:'DeName是一个建立在Handshake上的去中心化的域名拍卖项目。一个去中心化的命名协议。通过DeName，用户可以以完全无信任和去中心化的方式注册、购买、转让和拍卖顶级域名。',
        auction:'域名拍卖板块，拍卖过程完全在区块链上进行, 采用维克里拍卖模式。',
        market:'二级市场模块，实现了域名的去中心化交易，用户可以自由的出价或直接购买，一切都在链上进行。',

    }
}
