<template>
  <div class="container px-5 mx-auto my-8">
    <h2 class="font-bold mb-6 text-xl md:text-2xl">
      {{ t('sell.market') }}
      <router-link title="Market Help Docs" :to="{ path: `/docs` }">
        <svg-icon icon-class="tooltip" size="small" class="cursor-pointer inline-block w-4 h-4 ml-2" />
      </router-link>
    </h2>

    <div class="text-center text-gray-500/60">{{ t('sell.label') }}</div>
    <div class="text-center my-4 text-cyan-500 text-3xl font-bold">{{ props.detailData?.market.value / 1000000 }} HNS</div>

    <div class="flex items-center justify-center my-8" v-if="!props.detailData?.owner.own && (detailData?.market.orders.every((e: any) => !e.own) || props.detailData?.market.orders.length === 0 )">
      <label for="bid-modal" class="btn btn-info btn-sm text-white w-28 mr-4 hover:bg-blue-400">{{ t('sell.bid') }}</label>

      <button class="btn btn-sm bg-emerald-400 hover:bg-emerald-500 text-white w-28" @click="state.buyModal = true"> {{ t('sell.buyNow')}}</button>

      <input type="checkbox" id="bid-modal" class="modal-toggle" />
      <div class="modal">
        <div class="modal-box relative">
          <label for="bid-modal" class="btn btn-sm btn-outline hover:text-white btn-circle absolute right-2 top-2">✕</label>
          <h3 class="font-bold text-lg">{{ t('sell.bid') }}</h3>
          <div class="relative flex-grow my-4">
            <input type="text" oninput="value=value.replace(/[^\d.]/g, '')" v-model="state.bidValue" :placeholder="t('sell.enterAmount')" class="pl-4 focus:border-blue-400 w-full rounded-md border border-gray-300 h-12" />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="price-currency">HNS</span>
            </div>
          </div>

          <!-- <input type="text" :placeholder= "t('sell.enterAmount')" class="w-full my-4 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out h-12 m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" oninput="value=value.replace(/[^\d.]/g, '')" v-model="state.bidValue" /> -->
          <div class="flex items-center justify-end mt-4 modal-action">
            <label class="btn btn-ghost btn-sm hover:bg-gray-100 text-gray-500" for="bid-modal" @click="cancelBid">{{ t('common.cancel') }}</label>
            <button :class="{ loading: state.bidLoading }" class="btn btn-info btn-sm ml-4 text-white hover:bg-blue-400" @click="confirmBid">{{ t('common.confirm') }}</button>
          </div>
        </div>
      </div>

      <!-- Buy now modal -->
      <input type="checkbox" id="buy-modal" class="modal-toggle" v-model="state.buyModal" />
      <div class="modal">
        <div class="modal-box relative">
          <label for="buy-modal" class="btn btn-sm btn-outline hover:text-white btn-circle absolute right-2 top-2">✕</label>
          <h3 class="text-lg font-bold">{{ t('owner.buyNowTip') }}</h3>
          <p class="py-8">{{ t('owner.wantToContinue') }}</p>
          <div class="modal-action">
            <button :class="{ loading:state.isLoading }" class="btn btn-info text-white hover:bg-blue-400" @click="buyNow">{{ t('common.confirm') }}</button>
          </div>
        </div>
      </div>

    </div>

    <table class="table w-full">
      <thead>
        <tr>
          <th class="p-3">{{ t('sell.no') }}</th>
          <th class="p-3 text-right">{{ t('sell.time') }}</th>
          <th class="p-3 text-right">{{ t('sell.bid') }}</th>
          <th class="p-3 text-right">{{ state.character === 'guest' ? t('sell.status') : t('sell.operation')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(items,index) in detailData?.market.orders" :key="index">
          <td class="p-3 border-gray-500/10 text-gray-500/60 font-medium !text-left">{{ index + 1 }}</td>
          <td class="p-3 border-gray-500/10 text-gray-500/60 font-medium">{{ getTime(items.time) }}</td>
          <td class="p-3 border-gray-500/10 font-medium">
            {{ items.value / 1000000 }}
            <span class="text-gray-500/60 ml-1 font-normal">HNS</span>
          </td>
          <td class="border-gray-500/10">
            <div class="flex justify-end" v-if="state.character === 'guest'">
              <svg-icon size="small" v-if="items.confirmed" icon-class="complete" />
              <svg-icon size="small" v-else icon-class="waiting" />
            </div>

            <div v-else>
              <label for="deal-modal" class="text-indigo-600 cursor-pointer" @click="getExceptData(items)">{{ t('sell.deal') }}</label>

              <input type="checkbox" id="deal-modal" class="modal-toggle" />
              <div class="modal">
                <div class="modal-box text-left">
                  <h3 class="font-bold text-lg">{{ t('sell.saleHint') }}</h3>
                  <div class="text-left mt-6 leading-7">
                    {{ t('sell.hint2') }}
                  </div>
                  <div class="leading-7">{{ t('sell.hint3') }}</div>
                  <div>{{ t('sell.hint4') }}</div>
                  <div>{{ t('sell.hint5') }}</div>
                  <div class="modal-action">
                    <label for="deal-modal" class="btn btn-error w-20 hover:bg-red-900 text-white">{{ t('common.cancel') }}</label>
                    <label for="deal-modal" class="btn btn-info text-white hover:bg-blue-400" @click="makeDeal">{{ t('common.confirm') }}</label>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="detailData?.market.orders?.length < 1" class="text-center mt-5 text-sm text-neutral-400">{{ t('common.noData') }}</div>

    <div class="flex items-center justify-center mt-8 flex-wrap" v-if="!detailData?.owner.own && detailData?.market.orders.some((e: any) => e.own)">
      <label for="delete-modal" class="btn mt-2 w-40 mr-4 btn-error text-white hover:bg-red-800">{{ t('sell.delete') }}</label>
      <label for="makePrice-modal" class="btn mt-2 w-40 mr-4 btn-info text-white hover:bg-blue-400">{{ t('sell.changeOffer') }}</label>
      <!-- buy now -->
      <button class="btn text-white bg-emerald-400 mt-2 mr-4 hover:bg-emerald-500 w-40" @click="state.buyModal = true">{{ t('sell.buyNow') }}</button>

      <!-- Buy now modal -->
      <input type="checkbox" id="buy-modal" class="modal-toggle" v-model="state.buyModal" />
      <div class="modal">
        <div class="modal-box relative">
          <label for="buy-modal" class="btn btn-sm btn-outline hover:text-white btn-circle absolute right-2 top-2">✕</label>
          <h3 class="text-lg font-bold">{{ t('owner.buyNowTip') }}</h3>
          <p class="py-8">{{ t('owner.wantToContinue') }}</p>
          <div class="modal-action">
            <button :class="{ loading:state.isLoading }" class="btn btn-info text-white hover:bg-blue-400" @click="buyNow">{{ t('common.confirm') }}</button>
          </div>
        </div>
      </div>

      <!-- makePrice modal-->
      <input type="checkbox" id="makePrice-modal" class="modal-toggle" />
      <div class="modal">
        <div class="modal-box relative">
          <label for="makePrice-modal" class="btn btn-sm btn-outline hover:text-white btn-circle absolute right-2 top-2">✕</label>
          <h3 class="font-bold text-xl">{{ t('sell.changeOffer') }}</h3>
          <p class="py-4">{{ t('sell.currentPrice')}}<span class="pl-2 font-medium">{{(props.detailData?.market.orders.find((e: any) => e.own)).value / 1000000 }} HNS</span></p>

          <div class="relative flex-grow">
            <input type="text" oninput="value=value.replace(/[^\d.]/g, '')" v-model="state.price" :placeholder="t('common.amount')" class="pl-4 focus:border-blue-400 w-full rounded-md border border-gray-300 h-12" />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="price-currency">HNS</span>
            </div>
          </div>

          <div class="modal-action">
            <label for="makePrice-modal" class="btn btn-info text-white hover:bg-blue-400" @click="changePrice">{{ t('common.confirm') }}</label>
          </div>
        </div>
      </div>

      <!-- Delete modal -->
      <input type="checkbox" id="delete-modal" class="modal-toggle" />
      <div class="modal">
        <div class="modal-box relative">
          <label for="delete-modal" class="btn btn-sm btn-outline hover:text-white btn-circle absolute right-2 top-2">✕</label>
          <h3 class="text-lg font-bold">{{ t('owner.deletingBid') }}</h3>
          <p class="py-8">{{ t('owner.wantToContinue') }}</p>
          <div class="modal-action">
            <label for="delete-modal" class="btn btn-info text-white hover:bg-blue-400" @click="deletePrice">{{ t('common.confirm') }}</label>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>
  import { getTime } from '@/common'
  import { reactive, onMounted } from "vue"
  import { useI18n } from "vue-i18n";
  import { createSessions } from "@/utils/customRequest";
  import message from "@/components/message";
  import { deleteMarketPrice } from '@/api/hns'
  import { getBalance } from '@/api/dashboard'

  const props = defineProps(['detailData'])
  const emit = defineEmits(['reload'])
  const { t } = useI18n()

  const state = reactive<any>({
    modalShow: false,
    bidValue:null,
    isOwn:false,
    price:null,
    character:'guest',
    exceptData:{
      txid:'',
      index:null
    },
    isLoading:false,
    bidLoading:false,
    buyModal:false,
    balance:0
  })
  state.character = props.detailData?.owner.own ? 'owner' : 'guest'

  const emitFunction = () => {
    state.isLoading = false
    state.bidLoading = false
    state.buyModal = false
    emit('reload', props.detailData.name)
  }

  const confirmBid = () => {
    state.bidLoading = true
    if (Number(state.bidValue) < 0.01) {
      message({ type:'error',str:t('common.least') })
      state.bidLoading = false
      return
    }
    const params = {
      name: props.detailData.name,
      // version: 1,
      value: state.bidValue * 1000000,
    }
    createSessions('hns_market_bid', params, emitFunction, 'buy')
  }

  const buyNow = () => {
    if (state.balance < props.detailData?.market.value / 1000000) {
      message({ type:'error',str:t('common.notenough') })
      state.bidLoading = false
      return
    }
    state.isLoading = true
    const params = {
      name: props.detailData.name,
      type:'buy',
      txid: props.detailData.owner.txid,
      index:props.detailData.owner.index
    }
    createSessions('hns_market_buy', params, emitFunction, 'buy', 'fill')
  }

  // delete market price
  const deletePrice = () => {
    const data = {
      name: props.detailData.name,
    }
    deleteMarketPrice(data).then((res) =>{
      console.log(res)
      if(res) {
        message({ type:'success',str:'Operate successfully' })
        emitFunction()
      }
    })
  }

  // change price send same request as make bid
  const changePrice = () => {
    if(Number(state.price) < 0.01) {
      message({ type:'error',str:t('common.least') })
      return
    }
    const params = {
      name: props.detailData.name,
      // version: 1,
      value: state.price * 1000000,
    }

    createSessions('hns_market_bid', params, emitFunction, 'buy')
  }

  const getExceptData = (data: any) => {
    state.exceptData.index = data.index
    state.exceptData.txid = data.txid
  }

  // seller accept the bid
  const makeDeal = () => {
    const params = {
      name: props.detailData.name,
      txid: state.exceptData.txid,
      index: state.exceptData.index,
      type: 'sale'
    }

    createSessions('hns_market_sell', params, emitFunction, 'sale', 'fill')
  }

  const cancelBid = () => {
    state.bidValue = null
  }

  const getBalanceByAddress = async () => {
    if(!localStorage.getItem('key')) {
      state.balance = 0
      return
    }
    const res = await getBalance()
    state.balance = res.data.balance
  }

  onMounted(() => {
    getBalanceByAddress()
  })

</script>

<style scoped>
th {
  background-color: #F6F6F6;
  border-bottom: 1px solid #eee;
}

td {
  text-align: right;
}
</style>
