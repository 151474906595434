import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import Meta from '@/utils/meta'
import * as buffer from "buffer";
import '@/permission'

import "tailwindcss/tailwind.css"
import 'virtual:svg-icons-register'

import SvgIcon from '@/components/svgIcon/Index.vue'
import Spinner from '@/components/spinner/Index.vue'
import WalletQrcode from './components/WalletQrcode.vue'
import message from './components/message/index'

import i18n from './locale';
import VueSocialSharing from 'vue-social-sharing'

if (typeof (window as any).global === "undefined") {
    (window as any).global = window;
}
if (typeof (window as any).Buffer === "undefined") {
    (window as any).Buffer = buffer.Buffer;
}
const app = createApp(App)
app.component('svg-icon', SvgIcon)
app.component('qr-code', WalletQrcode)
app.component('spinner', Spinner)

app.use(router)
app.use(store)
app.use(Meta)
app.use(i18n)
app.use(VueSocialSharing)

app.mount('#app')

// global component
app.config.globalProperties.$message = message
app.config.globalProperties.$i18n = i18n.global.locale