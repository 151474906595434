export default {
    common: {
        price:'Price',
        amount:'Price',
        more:'More',
        noData: 'No Data',
        minute:'m',
        day:'d',
        hour:'h',
        domain:'Domain',
        least:'Bids cannot be less than 0.01HNS',
        notenough:'Insufficient balance',
        cancel:'Cancel',
        confirm:'Confirm',
        transfer:'Transfer',
        purchase:'Purchase',
        operationCancel:'Operation Cancelled',
        codeSent:'Code has been sent',
        connectTip:'Please connect your wallet',
        notConnected:'Your connection has been disconnected',
        disconnect:'Connection has been disconnected',
        notOperated:'Please finish your operations on the app side',
        operateSuccessFully:'Operate Successfully',
        finalize:'Finalize',
        language:'Language',
        account:'Account',
        return:'Return',
        copy:'Copy Successfully',

    },
    router: {
        home:'Home',
        auction:'Auction',
        market:'Market',
        transaction:'My Transaction',
        docs:'Docs',
        myDomain:'My Domain',
        myMarket:'My Market',
        setting:'Setting',
        logout:'Logout'
    },
    home: {
        category:'Categories',
        wallet:'Connect',
        topBids:'Top Bids',
        domainName:'Domain Name',
        topSale:'Top Sales',
        topAuction:'Top Auctions',
        plus:'Bidding Price (+ added blind)',
        bulkSearch:'Bulk Search',
        weekTop:'Week Top Bids',
        monthTop:'Month Top Bids',
        searchPlaceholder:'Search for your perfect domains',
        search:'Search',
        reserve:'This domain is reserved.',
        backTo:'Back to',
        connected:'Connected Wallet Address:'
    },
    auction: {
        dealPrice:'Price',

        auction:'Auction',
        opening:'Opening',
        bidding:'Bidding',
        reveal:'Reveal',
        sold:'Sold',
        domain:'Domain',
        bidTimes:'Number of bids',
        leftTime:'End of distance',
        untilEnd:'End of distance',
        leftBlock:'Remaining blocks',
        progress:'Process',
        lock:'Inactive',
        unlock:'Open',
        countDown:'Expect to start in',
        yourPrice:'Your bid',
        blindPrice:'Blind bid (Optional)',
        bidNow:'Bid now',
        estimateEnd:'Time left',
        settle:'Settle',
        inReveal:'In reveal',
        revealing:'Revealing',
        closed:'Closed',
        hasClosed:'Auction Over',
        closePrice:'Final price',

        redeem:'Redeem',
        endTip:'The distance time is the best \nestimate time.During this \nperiod, it may be delayed due \nto block delays.',
        reserve:'The current domain is reserved',
        tryOther:'The domain you searched for is a reserved domain. Please try searching for another domain',
        auctionInfo:'Domain Information',
        invalid:'Invalid Domain',

        last:'The last',
        notOperational:'minutes is the settlement period.  The domain names is not operational during this period.',
        bidSucceed:'Bid successfully',
        bidAgain:'Bid again'
    },
    sell: {
        market:'Market',
        label:'Buy now price',
        time:'Time',
        bid:'Bid',
        operation:'Operation',
        deal:'Sell',
        no:'No.',
        status:'Status',
        saleHint:'Sell Operation Hint',
        hint2:'This operation will deduct a 0.5% service fee(charged by ',
        hint3:'FxWallet) and the on-chaining fee of the transaction. You will ',
        hint4:'receive the amount deducted after the transaction is ',
        hint5:'completed. Make sure you want to accept this bid.',
        delete:'Delete',
        edit:'Change',
        buyNow:'Buy Now',
        changeOffer:'Modify',
        currentPrice:'Current Price:',
        enterAmount: 'Enter the amount',
    },
    rule: {
        auctionRule:'Rules',
        rule1:'Handshake will release a batch of new domain names every week. After the first bid, the auction will be open for 5 days. Everyone can participate in the bidding and each user can bid multiple times.',
        rule2:'After bidding ends, there is a 10 day reveal period where bidders reveal their actual bids. The winner will pay the actual bid amount of the second highest bidder, and obtains the ownership of the name.',
        rule3:'If you are not the highest bidder, please redeem the domain name. You can check the domain name in Pending Redemption.'
    },
    info:{
        auctionInfo:'Auction Info',
        auctionRelease:'Release Date',
        auctionOpen:'Auction Start Date',
        bidDeadline:'Bid Deadline',
        auctionEnd:'Auction Close Date',
        currentHeight:'Current Block Height',
    },
    record: {
        record:'Auction Records',
        bidAndBlind:'Bid + Blind',
        price:'Amount',
        status:'Status',
        unRevealed:'Unrevealed',
        auctionHistory:'Auction Histories',
    },
    history:{
        sale:'Sale History',
        no:'No.',
        time:'Time',
        final:'Final Price'
    },
    owner: {
        activate:'Activate',

        transfer:'Transfer',
        renew:'Renew',
        transferring:'You are transferring the domain name: pending',
        later:'The domain is transferring, please check the result later.',
        inputTransfer:'Enter the transfer address',
        cancelTransfer:'Cancel Transfer',
        confirmTransfer:'Confirm Transfer',
        currentHeight:'Current block height',
        renewEnd:'Renewal end block',
        expireTime:'Expiration time',
        renewNow:'Renew now',
        sell:'Sell',
        sale:'Sell',
        lock:'Lock',
        confirm:'Confirm',
        bid:'Bid',
        complete:'Complete',
        unlock:'Unlock',
        unlockTip:'Unlock Operation Tips',
        continue:'Now, your domain name is locked successfully.You can continue to ask a bid on the name.',
        cancelTip:'The "unlock" operation is to cancel the sale.',
        changeLabelPrice:'Change price',
        labelPrice:'Make price',
        enterPrice:'Enter the price',
        price:'Amount',
        transferLockHint:'Your domain is being transferred/locked. Please confirm/cancel the opearation',
        transferUp:'Your domain is being transferred/locked. Please confirm/cancel ',
        transferDown:' later',
        expire:'Domain is about to expire',
        inProgress:'Transaction in progress，please wait',
        notActivate:'This domain is not activated and cannot be transferred or renewed now',
        confirmAndCancel:'Your domain is being transferred/locked. Please confirm/cancel ',
        deleteAndContinue:'You are deleting your bid. Do you want to continue?',
        deletingBid: 'You are deleting your bid.',
        buyNowTip:'You are paying full cost to buy this domain name. ',
        wantToContinue: 'Do you want to continue?',
        invalidAddress:'Invalid address, please check and enter again',

    },
    generator: {
        generator:'Generator',
        pattern:'Pattern',
        example:'Example:',
        inputPattern:'Enter your pattern, i.e. AABBCC',
        inputDomain:'Enter multiple domains, separating with commas, spaces, new lines',
        modifier:'Modifier(optional)',
        fix:'Fix',
        prefix:'Prefix',
        suffix:'Suffix',
        repetition:'Number of repetition',
        filter:'Filter(optional)',
        startOrEnd:'Start/End with',
        startWith:'Start with',
        endWith:'End with',
        containOrNot:'Contains or not',
        contain:'Contains',
        notContain:'Not contains',
        search:'Search',

        bulkSearch: 'Bulk Search',
        nothing:'nothing on your mind ?',
        generateOne:'Generate one',
        clearAll:'Clear all',
        bid:'Bid',
        continue:'Back to Search',
    },
    market: {
        onSale:'On sale',
        saleRank:'Ranking',
        shelfTime:'Release Time',
        dealTime:'Deal time',
        domainPrice:'Domain price'
    },
    trade:{
        bidTimes:'Bid Times',
        revealAll:'Reveal all',
        redeemAll:'Redeem all',
        bidding:'Bidding',
        waitForReveal:'Unreveal',
        revealing:'Revealing',
        redeem:'Redeem',
        leftTime:'Left Time'
    },
    dashboard:{
        my:'My Domains',
        myDomain: 'Domain',
        market: 'Market',
        deviceXpubKey:'Device Xpubkey',
        all:'All',
        notActivate:'Not activated',
        expireSoon:'Expires soon',
        myMarket:'My Market Orders',
        selling:'On sale',
        waitForSale:'For sale',
        notForSale:'Not for sale',
        myOffer:'My bid',
        waitForConfirm:'To be confirmed',
        sold:'Sold',
        emailSetting:'Email Setting',
        forSafety:'For receiving important notifications of domain transfers, renewals, sales, etc.',
        notSet:'Not set',
        set:'Set',
        bind:'Bind',
        emailBind:'Bind email',
        bidTimes:'Bid Times',
        bidPrice:'Bidding Price',
        setting:'Setting',
        change:'Change',
        changeSuccess:'Changed Successfully',
        bindSuccess:'Bind Successfully',
        inputCode:'Please enter verification code',
        transferring:'Transferring'

    },
    title:{
        home:'Home',
        search:'Search',
        generator:'Generator',
        result:'Result',
        category:'Categories',
        auction:'Auction',
        detail:'Domain Detail',
        market:'Market',
        setting:'Setting',
        name:'My Names',
        marketCenter:'My Market',

    },
    docs:{
        marketHelp: 'Market Help Docs',
        decentralization: 'Decentralization',
        decenterDescription: 'The Market uses the protocol specifications of <a title="Non-Interactive Name Atomic Swaps" href="https://hsd-dev.org/HIPs/proposals/0001/">HIP-0001</a> and <a title="Trustless Live Auctions for Handshake" href="https://hsd-dev.org/HIPs/proposals/0006/">HIP-0006</a> to realize decentralized name transactions, and the transactions are on the chain to ensure the security of names.',
        saleName: 'Selling Names',
        lockName: '1. Preparation for sale - locking the name',
        lockNameDescription: 'Before selling, you need to transfer the name to the script address belonging to you and wait about two days before sending the transaction for finalize or cancel. A finalized name can proceed to the next step, while a cancellation will return to the original status.',
        bidName: '2. On sale - make an offer',
        bidNameDescription: 'After confirming locking, you can ask a bid on the name, and the bid can be changed at any time during the sale.',
        counterName: '3. On sale - counter offer',
        counterNameDescription: 'During the process of sale, buyers can offer their desired price and the list of bids will be displayed in the name details.',
        fillSale: '4. Completed - sale completion',
        fillPurchase: 'During the sale, the buyer can buy the name directly at the buy it now price, and the sale will be completed after the transaction is on the chain.',
        fillCounter: 'While waiting for others to buy, they may also make a counter offer on the name; the counter offer will be listed in the name details, you may also accept the offer and complete the sale directly.',
        fillHint1: 'Note: After accepting the offer, the actual HNS received will be slightly less than the offer price, because two parts of it will be deducted: 0.5% service fee charged by FxWallet and the transaction on-chaining fee.',
        cancelSale: '5. Completed - cancel the sale',
        cancelSaleDescription: 'During the period, you can also cancel the sale. Unlock the name and wait for about two days, then you can confirm the unlocking.',
        cancelSaleHint1: 'Note: Unlocking can only be confirmed, not cancelled. If you change your mind, you need to confirm the unlocking first and then re-lock.',
        unlockTitle: 'Unlock Operation Hint',
        unlockOpHint: 'Now, your domain name is locked successfully. You can continue to ask a bid on the name.\n\nThe "unlock" operation is to cancel the sale.',
        buyName: 'Buying Names',
        showOnSale: '1. Names available for buying',
        showOnSaleDescription: 'The Market will list all names currently for sale, and the specific sale status will perform in the name detail.',
        toCounterName: '2. Counter Offer',
        toCounterNameDescription: 'If you are not satisfied with the bid price, you can also make a counter-offer by entering your desired price and waiting for confirmation from the seller; the bid can be modified at any time.',
        toCounterNameHint1: 'Note: Since the transaction is completely decentralized, each bid will require you to have a UTXO with just the right price. Therefore, if there is no current UTXO that meets the conditions, you will first send a transaction to yourself to integrate the right UTXO, and this process will be on the chain and consume the handling fee.',
        toCounterNameHint2: 'Note: The bid does not go on the chain, so in effect the UTXO can still be spent. We try to ensure that you pay for unbid UTXO first, and if you have to use them, you will be prompted before you pay. If you see the prompt and choose to spend it anyway, the previous bid will be invalid.',
        buyNowName: '3. Direct Purchase',
        buyNowNameDescription: 'You can also choose to buy the name directly, you will pay the buy it now price plus a service fee of HNS to successfully purchase the name.',
        confirmName: '4. Purchase Confirmation',
        confirmNameDescription: 'After a successful purchase, you need to wait for about two days and then make a purchase finalize. After the finalization, the name will officially belong to you.',
    },
    seo: {
        home:'DeName is a decentralized domain auction project built on Handshake, a decentralized naming protocol. With DeName, users can register, purchase, transfer, and auction top-level domains in a completely trustless and decentralized manner.',
        auction:'Domain name auction board, the auction process is completely on the blockchain, using the Vickrey auction model.',
        market:'The secondary market place, which enables decentralized trading of domain names, allows users to bid freely or buy directly, all on the chain.',

    }

}
