import axios from '../utils/axios'

export function getAuctionList(type: any, data: any) {
  return axios({
    url: '/hns/wallet/auction/'+ type,
    method: 'post',
    data
  })
}

export function getMarket (params: any) {
  return axios({
    url:'hns/names',
    method:'get',
    params
  })
}

export function getDetail (name: any) {
  return axios({
    url:'hns/name/' + name,
    method:'get',
  })
}

export function getTransactionDetail (txid: string) {
  return axios({
    url:'wallet/hns/tx/' + txid,
    method:'get',
  })
}

export function getItemCounts (type: any) {
  return axios({
    url:'hns/names/summary/' + type,
    method:'get',
  })
}

export function sendTransaction(type:string, data: any) {
  return axios({
    url:`hns/wallet/auction/${type}`,
    method:'post',
    data
  })
}

export function getAvailableCoins(coin:string, params: any) {
  return axios({
    url:`/wallet/${coin}/coins`,
    method:'get',
    params
  })
}

export function createOrder(data: any) {
  return axios({
    url:'hns/market/order',
    method:'post',
    data
  })
}

export function fillMarketOrder(data: any) {
  return axios({
    url:'hns/market/fill',
    method:'post',
    data
  })
}

export function broadcastOrder(data: any) {
  return axios({
    url:'hns/market/broadcast',
    method:'post',
    data
  })
}

export function generateTx(coin:string, data: any) {
  return axios({
    url:`wallet/${coin}/tx`,
    method:'post',
    data
  })
}