<template>
  <div class="flex justify-between items-center">
    <h2 class="font-semibold text-xl">{{ title }}</h2>
  </div>
  <div class="overflow-hidden mt-4">
    <div class="grid grid-cols-10 mt-2">
      <div class="col-span-5 group text-gray-400 text-xs">{{ t('home.domainName') }}</div>
      <div class="col-span-5 group text-gray-400 text-right text-xs">{{ t('home.plus') }}</div>
    </div>
    <div v-if="state.dataList.length > 0" class="grid grid-cols-10 mt-4" v-for="(items, index) in state.dataList" :key="index">
      <router-link class="col-span-5 group text-indigo-400 cursor-pointer" :to="{ path: `/names/${items.name}` }">
        {{ punycodeTransfer(items.name) }}
      </router-link>
      <div class="col-span-5 group text-right">
        {{ addCommas(items.value) }} <span class="text-gray-500/60">HNS</span>
      </div>
    </div>

    <spinner v-else />

  </div>
</template>
<script lang="ts" setup>
import { reactive, onMounted } from 'vue';
import { getRankList } from "@/api/hns";
import { useI18n } from "vue-i18n";

import { punycodeTransfer, addCommas } from "@/common";

const { t } = useI18n()
const props = defineProps(['type','title'])
const state = reactive<any>({
  dataList: [],
  listQuery:{
    rank: ''
  }
})

const loadData = () => {
  state.listQuery.rank = props.type
  getRankList(state.listQuery).then((res) => {
    state.dataList = res.data.items
  })
}

onMounted(() => {
  loadData()
})
</script>
