<template>
  <!-- menu - start -->
  <div class="w-full max-w-screen-lg lg:block overflow-hidden mx-auto my-8 md:w-1/2">
    <div :style="{ color: state.activeColor }" class="pl-4 pt-4">{{ handleOwnerHint() }}</div>
    <div class="flex flex-wrap gap-4 m-2 items-center justify-center">

      <div class="group flex justify-center gap-4" v-if="detailData.status === 'CLOSED' && state.owner?.own && !state.owner?.spent && state.owner?.type === 'REVEAL'">
        <button @click="activateDomain" :class="{ loading: state.activateLoading }" class="w-32 h-10 flex justify-center items-center shrink-0 bg-indigo-500 group-hover:bg-indigo-600 group-active:bg-indigo-700 text-white rounded-lg transition duration-100">
          <svg-icon icon-class="activate" size="small" class="cursor-pointer w-16" />
          <span class="ml-1 text-sm cursor-pointer font-semibold">{{ t('owner.activate') }}</span>
        </button>
      </div>

      <div class="group flex gap-4 justify-center">
        <button :disabled="state.disableButton" @click="state.showTransfer = true" :class="{ loading: state.transferLoading }" class="btn btn-sm w-32 h-10 flex justify-center items-center shrink-0 bg-indigo-500 group-hover:bg-indigo-600 group-active:bg-indigo-700 text-white rounded-lg transition duration-100">
          <svg-icon size="miniPlus" icon-class="transfer" class="cursor-pointer" />
          <span class="ml-1 text-sm cursor-pointer font-semibold">
            {{ t('owner.transfer') }}
          </span>
        </button>
        <!-- transfer modal -->
        <input type="checkbox" id="transfer-modal" class="modal-toggle" v-model="state.showTransfer" />
        <div class="modal">
          <div class="modal-box relative">
            <label for="transfer-modal" class="btn btn-sm btn-outline hover:text-white btn-circle absolute right-2 top-2" @click="state.transferAddress = null">✕</label>
            <h3 class="font-bold text-lg mb-4">{{ t('owner.transfer') }}</h3>
            <div v-if="state.owner?.type === 'TRANSFER'">
              <!--  transfer progress  -->
              <div class="text-xl font-semibold">{{ t('owner.transferring') }}</div>
              <div class="mt-4 text-red-500" v-if="state.owner?.type ==='TRANSFER' && state.owner?.lock === SaleStep.LOCK && state.transferLockup > 0">
                {{ t('owner.later') }}
              </div>
              <div v-else class="flex items-center mt-6 justify-end">
                <button class="leading-4 w-32 mt-2 btn bg-red-500 btn-sm text-white mr-4 hover:bg-red-700" :class="{ loading: state.transferLoading }" @click="cancelTransfer">{{ t('owner.cancelTransfer') }}</button>
                <button class="leading-4 w-32 mt-2 btn bg-emerald-400 text-white hover:bg-emerald-500" :class="{ loading: state.transferLoading }" @click="confirmTransfer">{{ t('owner.confirmTransfer') }}</button>
              </div>
            </div>

            <div v-else>
              <base-input v-model="state.transferAddress" :placeholder="t('owner.inputTransfer')" />
              <button :disabled="!state.transferAddress" class="btn btn-info text-white hover:bg-blue-400 w-full mt-6" @click="transferNow">{{ t('common.transfer') }}</button>
            </div>

          </div>
        </div>
      </div>

      <div class="group flex gap-4 justify-center">
        <button :disabled="state.disableRenew" :class="{ loading: state.renewLoading }" @click="handleRenewModal" class="w-32 h-10 flex justify-center items-center shrink-0 bg-indigo-500 group-hover:bg-indigo-600 group-active:bg-indigo-700 text-white rounded-lg transition duration-100">
          <svg-icon size="miniPlus" icon-class="renew" class="cursor-pointer w-20" />
          <span class="ml-1 cursor-pointer font-semibold">
            {{ t('owner.renew') }}
          </span>
        </button>
        <!--  renew modal -->
        <input type="checkbox" id="renew-modal" class="modal-toggle" v-model="state.showRenew" />
        <div class="modal">
          <div class="modal-box relative">
            <label for="renew-modal" class="btn btn-sm btn-outline hover:text-white btn-circle absolute right-2 top-2">✕</label>
            <h3 class="font-bold text-lg mb-4">{{ t('owner.renew') }}</h3>
            <div class="flex items-center justify-between">
              <div class="text-gray-500">{{ t('common.domain') }}</div>
              <div class="font-semibold">{{ detailData.name }}</div>
            </div>
            <div class="flex items-center justify-between mt-4">
              <div class="text-gray-500">{{ t('owner.currentHeight') }}</div>
              <div class="font-semibold">{{ detailData.height }}</div>
            </div>
            <div class="flex items-center justify-between mt-4">
              <div class="text-gray-500">{{ t('owner.renewEnd') }}</div>
              <div class="font-semibold">{{ state.renewalBlock }}</div>
            </div>
            <div class="flex items-center justify-between mt-4">
              <div class="text-gray-500">{{ t('owner.expireTime') }}</div>
              <div class="font-semibold">{{ timeFormat(state.renewalBlock, state.nowHeight) }}</div>
            </div>
            <label for="renew-modal" class="btn btn-info text-white w-full mt-6 hover:bg-blue-400" @click="renewNow">{{ t('owner.renewNow') }}</label>
          </div>
        </div>
      </div>

      <div class="group flex gap-4 justify-center">
        <button :disabled="state.disableSale" @click="handleSaleOpen" class="w-32 h-10 flex justify-center items-center shrink-0 bg-indigo-500 group-hover:bg-indigo-600 group-active:bg-indigo-700 text-white transition duration-100">
          <label class="flex items-center">
            <svg-icon size="miniPlus" icon-class="sold" class="cursor-pointer w-20" />
            <span class="ml-1 cursor-pointer font-semibold">
              {{ t('owner.sell') }}
            </span>
          </label>
        </button>
      </div>
    </div>
  </div>

  <!-- if user want to sell this domain -->
  <div v-if="state.showSale && state.saleModal" class="container px-4 mx-auto my-12">
    <!-- title  -->
    <div class="flex justify-between relative">
      <h2 class="font-bold mb-6 text-2xl">{{ t('owner.sale') }}</h2>
      <span v-if="state.owner?.lock === 0" @click="state.saleModal = false" title="Close" class="btn btn-sm btn-outline hover:text-white btn-circle absolute right-2 top-2">✕</span>
    </div>

    <!-- tips here -->
    <div class="mb-4">{{ handleSaleHint() }}</div>

    <!-- sale progress -->
    <div class="flex items-center justify-center">
      <div class="flex relative pr-2 md:pr-8 items-center">
        <div class="w-4 h-4 md:w-6 md:h-6 rounded-full bg-indigo-500 inline-flex items-center text-white justify-center relative z-10">
          <svg-icon v-if="state.owner?.lock !== 0" icon-class="progress_done" size="mini"></svg-icon>
          <div v-else>1</div>
        </div>
        <div class="pl-2">
          <h3 class="font-medium title-font text-gray-900 my-1 text-sm md:text-base">{{ t('owner.lock') }}</h3>
        </div>
      </div>

      <div class="flex relative pr-2 md:pr-8 items-center">
        <div class="w-4 h-4 md:w-6 md:h-6 rounded-full inline-flex items-center justify-center text-white relative z-10" :class="{'bg-indigo-500': state.owner.lock > 0, 'bg-gray-400': state.owner.lock <= 0}">
          <svg-icon v-if="state.owner?.lock > 1" icon-class="progress_done" size="mini"></svg-icon>
          <div v-else>2</div>
        </div>
        <div class="pl-2">
          <h3 class="font-medium title-font text-gray-900 my-1 text-base">{{ t('common.confirm') }}</h3>
        </div>
      </div>

      <div class="flex relative pr-2 md:pr-8 items-center">
        <div class="w-4 h-4 md:w-6 md:h-6 rounded-full inline-flex items-center justify-center text-white relative z-10" :class="{'bg-indigo-500': state.owner.lock >= 2, 'bg-gray-400': state.owner.lock < 2}">
          <svg-icon v-if="state.owner?.lock > 2" icon-class="progress_done" size="mini"></svg-icon>
          <div v-else>3</div>
        </div>
        <div class="pl-2">
          <h3 class="font-medium title-font text-gray-900 my-1 text-base">{{ t('owner.bid') }}</h3>
        </div>
      </div>

      <div class="flex relative pr-2 md:pr-8 items-center">
        <div class="w-4 h-4 md:w-6 md:h-6 rounded-full inline-flex items-center justify-center text-white relative z-10" :class="{'bg-indigo-500': state.owner.lock >= 3, 'bg-gray-400': state.owner.lock < 3}">
          <div>4</div>
        </div>
        <div class="pl-2">
          <h3 class="font-medium title-font text-gray-900 my-1 text-base">{{ t('owner.complete') }}</h3>
        </div>
      </div>
    </div>
    <!-- operations here: lock the domain to prepare for sale -->
    <button v-if="state.owner?.lock === 0" :disabled="state.owner?.spent || !state.owner?.own || !state.isAvailable" class="btn btn-info text-white w-full my-12 hover:bg-blue-400" @click="lockDomain" :class="{ loading: state.sellLockLoading }">{{ t('owner.lock') }}</button>

    <!-- operations here: cancel your lock or confirm to sell   -->
    <div v-if="state.owner?.lock === 1" class="flex items-center justify-center mt-8">
      <button class="btn w-32 bg-slate-400 text-white hover:bg-slate-500" :class="{ loading: state.cancelLoading }" @click="cancelLock" :disabled="!state.owner.own || state.owner.spent || detailData.transfer + state.transferBlock - detailData.height > 0">{{ t('common.cancel') }}</button>
      <button class="btn ml-4 w-32 text-white bg-green-500 hover:bg-green-600" :class="{ loading: state.confirmLoading }" @click="confirmFinalize" :disabled="!state.owner.own || state.owner.spent || detailData.transfer + state.transferBlock - detailData.height > 0">{{ t('common.confirm') }}</button>
    </div>

    <!-- operations here: make price to sell   -->
    <div v-if="state.owner?.lock === 2" class="flex items-center justify-center mt-8">
      <!-- unlock domain button  -->
      <button class="font-semibold uppercase btn bg-orange-400 text-white mr-4 w-40 hover:bg-orange-500" :disabled="!state.owner.own || state.owner.spent" @click="state.showUnlock = true">{{ t('owner.unlock') }}</button>

      <!-- unlock modal  -->
      <input type="checkbox" id="unlock-modal" class="modal-toggle" v-model="state.showUnlock" />
      <div class="modal">
        <div class="modal-box">
          <h3 class="font-bold text-lg">{{ t('owner.unlockTip') }}</h3>
          <p class="py-4">{{ t('owner.continue') }}</p>
          <p class="py-4">{{ t('owner.cancelTip') }}</p>

          <div class="modal-action">
            <label for="unlock-modal" class="btn btn-error text-white hover:bg-red-800 w-20">{{ t('common.cancel') }}</label>
            <label for="unlock-modal" class="btn btn-info text-white hover:bg-blue-400 w-20" @click="unlockDomain">{{ t('common.confirm') }}</label>
          </div>
        </div>
      </div>

      <!-- change price and label price use the same modal -->
      <button v-if="detailData.market.value" class="btn btn-info text-white hover:bg-blue-400 w-40" @click="state.showPrice = true">{{ t('owner.changeLabelPrice') }}</button>

      <button v-else :disabled="!state.owner.own || state.owner.spent" class="btn w-40 btn-info text-white hover:bg-blue-400" @click="state.showPrice = true">{{ t('owner.labelPrice') }}</button>

      <!-- make price modal -->
      <input type="checkbox" id="price-modal" class="modal-toggle" v-model="state.showPrice" />
      <div class="modal">
        <div class="modal-box">
          <h3 class="font-bold text-lg">{{ t('owner.enterPrice') }}</h3>

          <div class="relative flex-grow mt-4">
            <input type="text" oninput="value=value.replace(/[^\d.]/g, '')" v-model="state.price" :placeholder="t('owner.price')" class="pl-4 focus:border-blue-400 w-full rounded-md border border-gray-300 h-12" />
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span class="text-gray-500 sm:text-sm" id="price-currency">HNS</span>
            </div>
          </div>

          <!-- <base-input :placeholder="t('owner.price')" class="mt-4" v-model="state.price" /> -->
          <div class="modal-action">
            <button class="btn btn-error btn-sm h-8 text-white hover:bg-red-900 w-20" @click="cancelMakePrice">{{ t('common.cancel') }}</button>
            <button class="btn btn-info btn-sm h-8 text-white hover:bg-blue-400 w-20" :class="{ loading: state.makePriceLoading }" @click="makePrice">{{ t('common.confirm') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- operations here: accept the bid -->
    <button v-if="state.owner.lock >= 3" class="w-full btn btn-info text-white hover:bg-blue-400 mt-8" :class="{ loading: state.confirmLoading }" :disabled="!state.owner.own || state.owner.spent || state.transferLockup > 0" @click="confirmFinalize">{{ t('common.finalize') }}</button>

  </div>

</template>

<script lang="ts" setup>
  import message from "@/components/message";
  import BaseInput from '@/components/Input.vue'
  import { reactive, ref, onMounted, watch, getCurrentInstance } from "vue";
  import { createSessions } from '@/utils/customRequest'
  import { SaleStep, timeFormat, HNS_OWNER_ACTIVE_TYPE, handleEndTime } from '@/common'
  import { useI18n } from "vue-i18n"

  const { t } = useI18n()
  const props = defineProps(['detailData'])
  const emit = defineEmits(['reload'])
  const { proxy } = getCurrentInstance() as any

  const state = reactive<any>({
    renewModal:false,
    saleModal:false,
    transferAddress:null,
    makeBidModal:false,
    price:null,
    activeColor:'',
    isLoading:false,
    transferLoading:false,
    renewLoading: false,
    cancelLoading:false,
    sellLockLoading:false,
    activateLoading:false,
    makePriceLoading:false,
    showTransfer:false,
    showRenew:false,
    showUnlock:false,
    showPrice:false,
    confirmLoading:false,
    owner:null,

    transferBlock: Number(import.meta.env.VITE_TRANSFER_BLOCK),
    isExpireSoon: false,
    isAvailable: false,
    isRenewal: false,
    disableButton: false,
    transferLockup: 0,
    disableSale: false,
    renewalBlock: Number(import.meta.env.VITE_RENEWAL_BLOCK),
    nowHeight: 0,
    showSale: false,
  })

  const windowWidth = ref(0)

  watch(() => window.innerWidth, (newWidth) => {
    windowWidth.value = newWidth
    // console.log(windowWidth.value)
  })
  watch(props, (n) => {
    // console.log('new', n)
    initData(n.detailData)
  })

  const handleSaleHint = () => {
    if(state.owner?.spent && (state.owner?.target['lock'] !== SaleStep.LOCK || state.owner?.lock !== SaleStep.LOCK)) return t('owner.inProgress')
    if(state.owner?.type ==='TRANSFER' && state.owner?.lock !== SaleStep.LOCK) {
      if(state.transferLockup <= 0) {return t('owner.transferLockHint')}
      const time = handleEndTime(state.transferLockup * import.meta.env.VITE_MODE_BLOCKTIME)
      return t('owner.transferUp') + `${time}` + t('owner.transferDown')
    }
  }

  // owner hint
  const handleOwnerHint = () => {
    if(state.isExpireSoon) {
      state.activeColor = '#ff9900'
      return t('owner.expire')
    }
    if(state.owner?.spent && state.owner?.target['lock'] === SaleStep.LOCK && state.owner?.lock === SaleStep.LOCK) {
      state.activeColor = '#ff9900'
      return t('owner.inProgress')
    }
    if(props.detailData.status ==='CLOSED' && state.owner?.type === 'REVEAL' && state.owner?.own && !state.owner?.spent) {
      state.activeColor = '#ff9900'
      return t('owner.notActivate')
    }
    if(state.owner?.type ==='TRANSFER' && state.owner?.lock === SaleStep.LOCK) {
      if(state.transferLockup <= 0) {
        state.activeColor = '#00cc33'
        return t('owner.transferLockHint')
      }
      const time = handleEndTime(state.transferLockup * import.meta.env.VITE_MODE_BLOCKTIME)
      state.activeColor = '#ff9900'
      return t('owner.transferUp') + ' ' + time + ' ' + t('owner.transferDown')
    }
  }

  const emitFunction = () => {
    state.renewModal = false
    state.isLoading = false
    state.cancelLoading = false
    state.renewLoading = false
    state.sellLockLoading = false
    state.transferLoading = false
    state.activateLoading = false
    state.showUnlock = false
    state.showPrice = false
    state.confirmLoading = false
    state.makePriceLoading = false
    state.price = null
    emit('reload', props.detailData.name)
  }

  // callback events of transfer
  const transferCallBack = () => {
    state.transferLoading = false
    state.showTransfer = false
    emit('reload', props.detailData.name)
  }

  const handleSaleOpen = () => {
    state.saleModal = true
  }

  // renew your domain
  const renewNow = () => {
    state.renewLoading = true
    const params = {
      name: props.detailData.name,
    }
    createSessions('hns_auction_renew', params, emitFunction, 'renewal')
  }

  // transfer domain
  const transferNow = () => {
    let regex = /^rs1([a-z]|\d){39}$/
    if(!state.transferAddress.match(regex)){
      proxy.$message({type:'error', str:t('owner.invalidAddress')})
      return
    }
    state.showTransfer = false
    state.transferLoading = true
    const params = {
      name: props.detailData.name,
      address:state.transferAddress
    }
    createSessions('hns_auction_transfer', params, transferCallBack, 'transfer')
  }

  // cancel transfer
  const cancelTransfer = () => {
    state.transferLoading = true
    const params = {
      name: props.detailData.name,
    }
    createSessions('hns_auction_cancel', params, transferCallBack, 'cancel')
  }

  // confirm transfer
  const confirmTransfer = () => {
    state.transferLoading = true
    const params = {
      name: props.detailData.name,
    }
    createSessions('hns_auction_finalize', params, transferCallBack, 'finalize')
  }

  // lock domain
  const lockDomain = () => {
    state.sellLockLoading = true
    const params = {
      name: props.detailData.name,
    }
    createSessions('hns_market_lock', params, emitFunction, 'lock')
  }

  // cancel lock
  const cancelLock = () => {
    state.cancelLoading = true
    const params = {
      name: props.detailData.name,
    }
    createSessions('hns_market_cancel', params, emitFunction, 'cancel')
  }

  // confirm
  const confirmFinalize = () => {
    state.confirmLoading = true
    const params = {
      name: props.detailData.name,
    }
    createSessions('hns_market_finalize', params, emitFunction, 'finalize')
  }

  // activate domain
  const activateDomain = () => {
    state.activateLoading = true
    const params = {
      name: props.detailData.name,
      data:{'records': [{'type': 'TXT', 'txt': ['hello fx-wallet']}]}
    }
    createSessions('hns_auction_update', params, emitFunction, 'update')
  }

  // unlock domain
  const unlockDomain = () => {
    // state.isLoading = true
    const params = {
      name: props.detailData.name,
    }
    createSessions('hns_market_unlock', params, emitFunction, 'unlock')
  }

  // make a price for your domain
  const makePrice = () => {
    state.makePriceLoading = true
    if(Number(state.price) < 0.01) {
      message({ type:'error',str:t('common.least') })
      state.makePriceLoading = false
      return
    }
    const params = {
      name: props.detailData.name,
      value:state.price * 1000000,
      version:1
    }
    createSessions('hns_market_price', params, emitFunction, 'sale')
  }

  const cancelMakePrice = () => {
    state.price = null
    state.showPrice = false
  }

  const handleRenewModal = () => {
    state.showRenew = true
    // state.renewLoading = true
  }

  const initData = (x: any) => {
    state.owner = x.owner
    state.transferBlock = Number(import.meta.env.VITE_TRANSFER_BLOCK)
    state.isExpireSoon =  state.owner?.own && !x.expired && x.status === 'CLOSED' && (Number(import.meta.env.VITE_RENEWAL_BLOCK) - (x.height) + (x.renewal)) <= Number(import.meta.env.VITE_EXPIRE_REMAIN)
    state.isAvailable = HNS_OWNER_ACTIVE_TYPE.some((e) => e === state.owner?.type)
    state.isRenewal = state.isAvailable && x.height > x.renewal + Number(import.meta.env.VITE_RENEW_SPAN)
    state.disableButton = state.owner?.type === 'REVEAL' || state.owner?.spent || state.owner?.lock !== SaleStep.LOCK

    state.disableSale = state.owner?.type === 'REVEAL' || state.owner?.spent || state.owner?.lock !== SaleStep.LOCK || state.owner.type === 'TRANSFER'
    state.disableRenew = state.owner?.type === 'REVEAL' || !state.isRenewal || x.expired || state.owner.spent || state.owner.lock !== SaleStep.LOCK
    state.transferLockup = x.transfer + Number(import.meta.env.VITE_TRANSFER_BLOCK) - x.height
    state.renewalBlock = x.renewal + Number(import.meta.env.VITE_RENEWAL_BLOCK)
    state.nowHeight = x.height

    state.showSale = x.status === 'CLOSED' && state.owner?.own && state.owner?.type !== 'REVEAL' && !x.expired
    if(state.owner?.type === 'REVEAL' || x.expired || state.owner?.spent || state.owner?.lock !== SaleStep.LOCK) state.saleModal = true
  }

  // get screen width
  const getWindowResize = function () {
    windowWidth.value = window.innerWidth
    // console.log(windowWidth.value)
  }
  onMounted(() => {
    getWindowResize()
    window.addEventListener('resize', getWindowResize)
    setTimeout(() => {
      initData(props.detailData)
    }, 50);
  })

</script>
