<template>
  <div class="max-w-screen-xl mx-auto overflow-hidden">
    <div class="container px-2 md:px-4 mx-auto flex flex-wrap">
      <div @click="goBack" class="flex text-sm items-center text-gray-400 pt-4 cursor-pointer">
        <ArrowLeftIcon class="w-4 h-4 mr-1" />
        {{ t('common.return') }}
      </div>
    </div>

    <div class="flex flex-col text-center w-full">
      <h1 class="text-2xl md:text-5xl font-bold title-font mt-8 md:mt-16 flex items-center justify-center ">
        {{ punycodeTransfer(route.params.name as string).length > 30 ? cutString(punycodeTransfer(route.params.name as string),4,6) : punycodeTransfer(route.params.name as string) }}
        <span v-if="(route.params.name as string).startsWith('xn--')">({{ shortenString(route.params.name,10) }})</span>
        <span class="ml-2 cursor-pointer copy" :data-clipboard-text="route.params.name" @click="copy">
          <DocumentDuplicateIcon class="h-4 w-4 ml-2 mt-2 text-gray-400" />
        </span>
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton class="flex items-center rounded-full">
              <span class="sr-only">Open options</span>
              <ShareIcon class="h-4 w-4 mt-2 ml-4 text-gray-400" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="py-2 absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="text-sm px-4 py-2">Share this link to</div>
              <MenuItem v-for="items in networks" v-slot="{ active }">
                <ShareNetwork
                  :network="items.network"
                  :key="items.network"
                  :url="sharing.url"
                  :title="sharing.title"
                  :description="sharing.description"
                  :quote="sharing.quote"
                  :hashtags="sharing.hashtags"
                  :twitterUser="sharing.twitterUser"
                  class="flex items-center"
                >
                  <div :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" class="isolate inline-flex rounded-md w-full h-10 items-center">
                    <img class="w-6 h-6 mr-4" :src="items.img" alt="social_logo">
                    <div class="w-full" >{{ items.name }}</div>
                  </div>
                </ShareNetwork>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </h1>
    </div>
    <!-- stats bar -->
    <div class="shadow my-8 lg:mx-48 overflow-visible rounded-lg">
      <div class="mx-auto max-w-7xl px-2 py-4 lg:px-8">
        <dl class="grid grid-cols-1 gap-y-16 text-center grid-cols-3">
          <div class="mx-auto flex max-w-xs flex-col md:gap-y-4">
            <dt class="text-xs md:text-base leading-7 text-gray-400">{{ t('auction.bidTimes') }}</dt>
            <dd class="order-first text-base md:text-2xl font-semibold tracking-tight text-gray-900 sm:text-4xl">{{ state.domainDetail?.history.length }}</dd>
          </div>

          <div class="mx-auto flex max-w-xs flex-col md:gap-y-4">
            <dt class="text-xs md:text-base leading-7 text-gray-400">{{ t('auction.leftBlock') }}</dt>
            <dd class="order-first text-base md:text-2xl font-semibold tracking-tight text-gray-900 sm:text-4xl">{{ handleLeftBlocks(state.open, state.height) < 0 ? '--' : handleLeftBlocks(state.open, state.height) }}</dd>
          </div>

          <div class="mx-auto flex max-w-xs flex-col md:gap-y-4">
            <dt class="text-xs md:text-base leading-7 text-gray-400 flex items-center justify-center">
              {{ t('auction.untilEnd') }}
              <tool-tip :tooltip="t('auction.endTip')" :direction="directionRef" class="md:pl-2 text-xs md:text-base" />
            </dt>
            <dd class="order-first text-base md:text-2xl font-semibold tracking-tight text-gray-900 sm:text-4xl">{{ handleLeftBlocks(state.open, state.height) < 0 ? '--' : handleEndTime(blockTime * handleLeftBlocks(state.open, state.height)) }}</dd>
          </div>
        </dl>

      </div>
    </div>

    <div v-if="!state.domainDetail?.reserved">
      <owner-method @reload="loadDetail" v-if="state.domainDetail?.owner.own && state.domainDetail?.status !== 'REVEAL' && !state.domainDetail?.expired" :detailData="state.domainDetail" />
      <market-history v-if="state.marketHistory.length > 0" :data="state.marketHistory" />
      <market-section @reload="loadDetail" v-if="state.isShowMarket && state.domainDetail?.market.value !== null && !state.domainDetail?.expired" :detailData="state.domainDetail" />
      <domain-auction @reload="loadDetail" :history="state.recordData" :isSettle="state.isSettle" :statusNum="state.statusNum" :status="state.status" :detailData="state.domainDetail" :endTime="handleLeftBlocks(state.open, state.height)" />
      <auction-record v-if="state.domainDetail?.status !== 'INACTIVE'" class="my-12" :detailData="state.recordData" />
      <auction-histories v-if="state.domainDetail?.old_history.length > 0" :data="state.oldHistory" class="my-12" />
      <auction-info v-if="state.domainDetail?.status !== 'INACTIVE'" class="mb-12" :detailData="state.domainDetail" />
      <auction-rule />
    </div>
    <div v-else class="mt-12">
      <div class="text-gray-400 text-xl text-center">{{ t('auction.reserve') }}</div>
      <div class="text-gray-400 text-lg text-center mt-4">{{ t('auction.tryOther') }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, getCurrentInstance, watch, ref } from "vue";
import { getDetail } from '@/api/auction'
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n"
import { punycodeTransfer, SaleStep, handleEndTime, shortenString, cutString } from '@/common'
import { ArrowLeftIcon, ShareIcon, DocumentDuplicateIcon } from '@heroicons/vue/20/solid'

import DomainAuction from './components/DomainAuction.vue'
import AuctionRecord from './components/AuctionRecord.vue'
import AuctionInfo from './components/AuctionInfo.vue'
import AuctionRule from './components/AuctionRule.vue'
import MarketSection from './components/MarketSection.vue'
import OwnerMethod from './components/OwnerMethod.vue'
import AuctionHistories from "./components/AuctionHistories.vue";
import MarketHistory from './components/MarketHistory.vue'
import ToolTip from '@/components/ToolTip.vue'
import twitter from '@/assets/images/twitter.png'
import telegram from '@/assets/images/telegram.png'
import Clipboard from 'clipboard'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const { t } = useI18n()
const { proxy } = getCurrentInstance() as any
const route = useRoute()
const directionRef = ref('bottom')

const blockTime = Number(import.meta.env.VITE_MODE_BLOCKTIME)
const windowWidth = ref(0)
const state = reactive<any>({
  domainDetail:null,
  status:null,
  recordData:[],
  open:null,
  height:null,
  statusNum:null,
  isSettle:true,
  oldHistory:[],
  isShowMarket:false,
  marketHistory:[],
})
const sharing = {
  url: `https://dename.id${route.path}`,
  title: 'What a great Domain I get! Checkout this site to get one of yours.',
  description: 'Dename.id - Discover and bid your favourite domains.',
  quote: '',
  hashtags: 'dename,hns,domain',
  twitterUser: 'fxwallet'
}

const networks = [
  { network: 'telegram', name: 'Telegram', img: telegram, color: '#0088cc' },
  { network: 'twitter', name: 'Twitter', img: twitter, color: '#1da1f2' },
]
watch(route, (n: any) => {
  if(n.params.name) loadDetail(n.params.name)
})

document.title = route.params.name as any + ' - DeName' || 'DeName'
proxy.$setMeta(route.params.name)



const loadDetail = (name: string) => {
  getDetail(name).then((res: any) => {
    if(res) {
      state.domainDetail = res.data
      state.status = res.data.status
      state.statusNum = handleStatusNum(res.data.status)
      state.recordData = res.data.history
      state.oldHistory = res.data.old_history
      state.marketHistory = res.data.market?.history
      state.height = res.data.height
      state.open = res.data.open

      if(res.data.status === 'REVEAL') {
        state.isSettle = state.open + Number(import.meta.env.VITE_MODE_REVEAL) - state.height > 1
      } else if(res.data.status == 'BIDDING') {
        state.isSettle = state.open + Number(import.meta.env.VITE_MODE_BIDDING) - state.height > 1
      }

      let owner = state.domainDetail?.owner
      let isSpent = false
      if(!owner?.own) isSpent = false
      if(owner?.spent) isSpent = true
      if(state.domainDetail?.market.txid !== owner?.txid) isSpent = true

      state.isShowMarket = (owner?.lock === SaleStep.SALE || owner?.lock === SaleStep.BOUGHT || owner?.lock === SaleStep.UNSHELVE) && true && !isSpent
    }
  })
}

const handleLeftBlocks = (open:any, height: any) => {
  return open + handleStatus(state.status) - height as any
}

const handleStatus = (status: any) => {
  switch (status) {
    default:
      return 0;
    case 'INACTIVE':
      return 0
    case 'OPENING':
      return Number(import.meta.env.VITE_MODE_OPENING)
    case 'BIDDING':
      return Number(import.meta.env.VITE_MODE_BIDDING)
    case 'REVEAL':
      return Number(import.meta.env.VITE_MODE_REVEAL)
    case 'CLOSED':
      return 0
  }
}

const handleStatusNum = (status: any) => {
  switch (status) {
    case 'INACTIVE':
      return 1;
    case 'OPENING':
      return 2
    case 'BIDDING':
      return 3;
    case 'REVEAL':
      return 4;
    case 'CLOSED':
      return 5;
    default:
      return 1;
  }
}
const getWindowResize = function () {
  windowWidth.value = window.innerWidth

  if(windowWidth.value < 920) {
    directionRef.value = 'left'
  } else {
    directionRef.value = 'bottom'
  }
}

const goBack = () => {
  history.go(-1)
}

const copy = () => {
  let clipboard = new Clipboard('.copy')
  clipboard.on('success', (e) => {
    proxy.$message({ type:'success', str: t('common.copy') })
    // release
    clipboard.destroy()
  })
  clipboard.on('error', (e) => {
    console.log('copy not support', e)
    clipboard.destroy()
  })
}

onMounted(() => {
  getWindowResize()
  window.addEventListener('resize', getWindowResize)
  loadDetail(route.params?.name as string)
})

</script>