<template>
  <div class="flex">
    <div class="w-full inline-flex items-center justify-end">
      <input
        class="
          form-control
          block
          w-full
          px-3
          py-1.5
          text-sm
          md:text-base
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          h-12
          m-0
          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
        "
          :placeholder="placeholder"
          :value="modelValue" @input="updateValue"
      />
      <span v-if="suffixIcon" class="flex items-center justify-end cursor-pointer absolute pr-3" @click="goBulkSearch">
        <img src="../assets/images/import.svg" alt="">
        <span class="ml-2 text-indigo-lg">{{ t('home.bulkSearch') }}</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useRouter } from 'vue-router';
  import { useI18n } from "vue-i18n";

  const { t } = useI18n()
  const props = defineProps(['placeholder','modelValue','suffixIcon'])
  const emit = defineEmits(['update:modelValue'])

  const updateValue = (event: any) => {
    emit('update:modelValue', event.target.value)
  }

  const router = useRouter()
  const goBulkSearch = () => {
    router.push('/search')
  }
</script>

<style scoped>

</style>
